@use "@optimizely/design-tokens/dist/scss/colors" as axiom-colors;
@import "../../oui/oui-utils";

.oui-sidebar {
    transition-property: max-width, width, left, right, visibility;
    transition-duration: 0.3s;
    top: 0;
    z-index: map-fetch($z-index, sidebar);
    &.background--smoke {
        background-color: axiom-colors.$smoke-extra-light;
    }
}
