.access-selections-form {
    display: flex;
    flex-direction: column;

    div.oui-dropdown-children,
    ul {
        li {
            min-width: 315px;
        }
    }

    // Some dumb override for the dropdown's width b/c axiom is bad.
    &__selection-row-dropdown {
        &:first-child {
            margin-right: var(--axiom-grid-margin-small);
        }

        width: 100%;
        min-width: 0;
        line-height: 1.5rem !important;

        button {
            width: 100%;
            min-width: 250px;
            text-align: start;

            &:hover:not([disabled]) {
                background-color: #fff;
            }
        }

        button + div {
            min-width: 250px;
        }

        ul {
            li {
                min-width: 250px;
            }
        }

        &--small {
            button {
                width: 180px;
                min-width: 180px;
            }
        }
    }

    &__selection-row-item {
        width: 100%;

        &--small {
            width: 25%;
        }
    }
}
