@use "sass:map";
@use "@optimizely/design-tokens/dist/scss/typography-map" as axiom-typography-map;

////
/// Reset
/// @description Using inuit.css reset.
////

// Box-sizing reset
// Reset all elements to use the border-box model.

[class*="oui-"],
[class*="axiom-"] {
    &,
    &::before,
    &::after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
}

/*
    This is a temporary fix until a Typography component is introduced.
    We don't want to modify the root font-size to 14px because it will
    disrupt the rem calculations, but we need to reset the components back
    to their 14px font size.
*/
[class*="oui-"],
[data-oui-component=true] {
    font-size: map.get(axiom-typography-map.$body, "font-size");
}

// General resets.

body {
    @include font-smoothing;
    margin: 0;
    color: map-fetch($color, text, base);

    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;

    font-feature-settings: "kern";
    font-kerning: normal; /* TODO: Test normal vs auto */
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
caption {
    margin: 0;
    padding: 0;
}

dl,
dd,
ol,
ul,
form,
fieldset,
legend,
table,
th,
td,
hr {
    &[class*="oui-"],
    &[class*="axiom-"] {
        margin: 0;
        padding: 0;
    }
}

// Remove default list-style.

ol,
ul {
    list-style: none;
}

// So that `alt` text is visually offset if images don't load.
// Set to display block by default.

img {
    font-style: italic;
}

// Give form elements some cursor interactions...

button,
select,
option,
input[type="radio"] + label {
    cursor: pointer;
}

[type="text"]:active,
[type="text"]:focus,
[type="password"]:active,
[type="password"]:focus,
[type="email"]:active,
[type="email"]:focus,
[type="number"]:active,
[type="number"]:focus,
[type="search"]:active,
[type="search"]:focus,
[type="url"]:active,
[type="url"]:focus,
[type="time"]:active,
[type="time"]:focus,
textarea:active,
textarea:focus {
    &[class*="oui-"],
    &[class*="axiom-"] {
        outline: none;

        // These will be removed when the Input component is synced, so for the moment we'll exclude new components
        &:not(.axiom-editable-dropdown__input) {
            cursor: text;
            box-shadow: 0px 0px 3px $brand-blue-dark;
        }

        &:disabled {
            box-shadow: none;
        }
    }
}

// ## Links

a {
    text-decoration: none;
}

// Improve readability when focused and also mouse hovered in all browsers.

a:active,
a:hover {
    outline: 0;
}

/// Typography

em {
    font-style: italic;
}

b,
strong {
    font-weight: 500;
}

/// Forms

// Improve readability and alignment in all browsers.

textarea {
    vertical-align: top;
}

// Re-set default cursor for disabled elements.

button[disabled],
html input[disabled] {
    cursor: default;
}

// Address inconsistent `text-transform` inheritance for `button` and `select`.
// All other form control elements do not inherit `text-transform` values.
// Correct `button` style inheritance.

button,
select {
    text-transform: none;
}

// 1. Correct font family not being inherited in all browsers.
// 2. Correct font size not being inherited in all browsers.
// 3. Address margins set differently in Firefox 4+, Safari 5, and Chrome.

button,
input,
select,
textarea {
    font-family: inherit; // 1
    font-size: 100%; // 2
    margin: 0; // 3
}

// Removes extra padding/spacing inside buttons, fixing alignments.

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

// Remove inner padding and search cancel button Chrome on OS X.

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

// 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
//    and `video` controls.
// 2. Correct inability to style clickable `input` types in iOS.
// 3. Improve usability and consistency of cursor style between image-type
//    `input` and others.

button,
html input[type="button"], // 1
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; // 2
    cursor: pointer; // 3
}

// Explicitly define input placeholder colors.
::-webkit-input-placeholder {
    color: map-fetch($color, text, muted);
}

:-moz-placeholder {
    // scss-lint:disable PseudoElement
    // Firefox 18-
    color: map-fetch($color, text, muted);
}

::-moz-placeholder {
    // Firefox 19+
    color: map-fetch($color, text, muted);
}

:-ms-input-placeholder {
    color: map-fetch($color, text, muted);
}

// Keep number inputs consistent for alignment/proximity to `%` symbol
// https://css-tricks.com/snippets/css/turn-off-number-input-spinners/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
