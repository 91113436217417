.user-group-form-users {
    &__table {
        border-collapse: separate;
    }

    &__inputs {
        padding-bottom: 27px;
        &--nopadding {
            padding-bottom: 0;
        }
    }
}
