////
/// Borders
/// @description This adds a border and additional spacing directly to an
/// element.
/// @example[html]
///   <div class="border--all soft">Border on all sides</div>
/// @example[html]
///   <div class="border--top soft">Border on top</div>
/// @example[html]
///   <div class="border--ends soft">Border on ends</div>
/// @example[html]
///   <div class="border--left soft">Border on left</div>
/// @example[html]
///   <div class="border--sides soft">Border on sides</div>
/// @example[html]
///   <div style="border-top: 1px solid #000;" class="no-border soft">Remove all borders</div>
/// @example[html]
///   <div style="border-top: 1px solid #000;" class="no-border--top soft">Remove top border</div>
////

body:target *[data-oui-component],
.highlight-react--oui *[data-oui-component],
.highlight-react--oui[data-oui-component] {
    outline: 2px solid hotpink;

    & & {
        outline-width: 1px;
    }
}

%border--all,
.border--all {
    border: 1px solid map-fetch($border, color, base) !important;
}

%border-dashed--all,
.border-dashed--all {
    border: 1px dashed map-fetch($border, color, base) !important;
}

%border--top,
.border--top {
    border-top: 1px solid map-fetch($border, color, base) !important;
}

%border--bottom,
.border--bottom {
    border-bottom: 1px solid map-fetch($border, color, base) !important;
}

%border--ends,
.border--ends {
    @extend %border--top;
    @extend %border--bottom;
}

%border--left,
.border--left {
    border-left: 1px solid map-fetch($border, color, base) !important;
}

%border--right,
.border--right {
    border-right: 1px solid map-fetch($border, color, base) !important;
}

%border--sides,
.border--sides {
    @extend %border--left;
    @extend %border--right;
}

// Remove Borders

.no-border {
    border: 0 !important;
}

.no-border--top {
    border-top: none !important;
}

.no-border--right {
    border-right: none !important;
}

.no-border--bottom {
    border-bottom: none !important;
}

.no-border--left {
    border-left: none !important;
}

.border-radius {
    border-radius: var(--border-radius);
}
