.layout-wrapper {
    --global-bar-height: 40px; // Maybe this can come from the global bar itself?
    min-height: calc(100vh - var(--global-bar-height)); // make up for global nav height

    &__navigation {
        position: sticky;
        top: var(--global-bar-height);
        height: calc(100vh - var(--global-bar-height));
    }
}
