////
/// OUI Layout
/// @description Flexbox 12-col grid based on Bootstrap 4.
////

/// Layout Padding Helpers
/// @description Apply inner padding on child rows, cols and self

@import "../../oui/oui-utils";

.padded-content--around.row,
div[class*="col"].padded-content--around {
    padding: spacer(1.5);
}
.padded-content--sides.row,
div[class*="col"].padded-content--sides {
    padding: 0 spacer(1.5);
}
.padded-content--ends.row,
div[class*="col"].padded-content--ends {
    padding: spacer(1.5) 0;
}
.padded-content--remove.row,
div[class*="col"].padded-content--remove,
.padded-content--remove .row > div[class*="col"] {
    padding: 0;
}

.padded-content--top.row {
    padding-top: spacer(1.5);
}

.padded-content--bottom.row {
    padding-bottom: spacer(1.5);
}

/// Layout Debug
/// @description For development use only. Not for production!
/// TODO[1]: Add test so folks can't use this in production
.outline--debug .container {
    outline: 1px solid hsla(330, 100%, 71%, 0.5);
    background: hsla(330, 100%, 71%, 0.15);
}
.outline--debug .row {
    outline: 1px solid hotpink;
    background: hsla(330, 100%, 71%, 0.15);
}
.outline--debug .col,
.outline--debug .row > div[class*="col"] {
    outline: 1px solid hsl(330, 100%, 71%);
    background: hsla(330, 100%, 71%, 0.15);
}

.push-rows--top .row + .row {
    margin-top: spacer(1.5);
}

/// Bootstrap flexbox grid container
.container,
.container-fluid {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-right: spacer(1.5);
    padding-left: spacer(1.5);
    margin-right: auto;
    margin-left: auto;
}

.container--pull .row [class*="col"]:first-child {
    padding-left: 0;
}
.container--pull .row [class*="col"]:last-child {
    padding-right: 0;
}

/// TODO[1]: Adjust breakpoints so they make sense for Optly
/// TODO[1]: Move to Tokens
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    margin-right: spacer(-1.5);
    margin-left: spacer(-1.5);
    min-width: 0;
    min-height: 0;
}

.row--no-wrap {
    flex-wrap: nowrap;
}

.gutters--remove {
    margin-right: 0;
    margin-left: 0;
}

.gutters--remove > .col,
.gutters--remove > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-small-1,
.col-small-2,
.col-small-3,
.col-small-4,
.col-small-5,
.col-small-6,
.col-small-7,
.col-small-8,
.col-small-9,
.col-small-10,
.col-small-11,
.col-small-12,
.col-small,
.col-small-auto,
.col-medium-1,
.col-medium-2,
.col-medium-3,
.col-medium-4,
.col-medium-5,
.col-medium-6,
.col-medium-7,
.col-medium-8,
.col-medium-9,
.col-medium-10,
.col-medium-11,
.col-medium-12,
.col-medium,
.col-medium-auto,
.col-large-1,
.col-large-2,
.col-large-3,
.col-large-4,
.col-large-5,
.col-large-6,
.col-large-7,
.col-large-8,
.col-large-9,
.col-large-10,
.col-large-11,
.col-large-12,
.col-large,
.col-large-auto {
    position: relative;
    width: 100%;
    padding-right: spacer(1.5);
    padding-left: spacer(1.5);
}

.col {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.col-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.col-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.col-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}

.col-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

div[class*="fitContent"] {
    display: block;
    width: auto;
    padding: 0;
}

// Styling for ColWithNubbin

.oui-config-panel__nubbin {
    position: absolute;
    transform: rotate(45deg);
    margin-left: -21px; //padding plus 1px border
}

.oui-config-panel__nubbin::before {
    position: absolute;
    width: $nubbin-size;
    height: $nubbin-size;
    content: "";
    border-left: 1px solid map-fetch($border, color, base);
    border-bottom: 1px solid map-fetch($border, color, base);
    background-color: map-fetch($color, background, light);
}
