@use "@optimizely/design-tokens/dist/scss/colors" as axiom-colors;
@import "../../oui/oui-utils";

.oui-sidebar-menu-item {
    height: 32px;
    padding: 7px 0;
    position: relative;
    &--submenu {
        display: flex;
        align-items: center;
        .axiom-typography--caption.oui-sidebar-menu-item__title {
            margin-left: 36px;
        }
    }
    &:hover {
        background: axiom-colors.$brand-blue-light;
        color: axiom-colors.$brand-blue-dark;
    }
    &:focus {
        outline: none;
        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 0;
            right: 3px;
            bottom: 0;
            left: 2px;
            border-radius: var(--border-radius);
            box-shadow: 0px 0px 3px axiom-colors.$brand-blue-dark;
            z-index: 1;
        }
    }
    &.is-active,
    &.is-active:hover {
        background: axiom-colors.$brand-blue-light;
    }
    &__title.axiom-typography--caption {
        margin-left: spacer(2);
        letter-spacing: -0.009rem;
    }
}
