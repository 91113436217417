////
/// Horizontal rules
/// @description Adds styling to an `hr`. Use this between blocks of text or
/// other elements to provide separation.
/// @example[html] Horizontal rule
///   <hr class="#{$namespace}rule">
/// @example[html] Dotted horizontal rule
///   <hr class="#{$namespace}rule #{$namespace}rule--dotted">
/// @example[html] Dashed horizontal rule
///   <hr class="#{$namespace}rule #{$namespace}rule--dashed">
////

%#{$namespace}rule,
.#{$namespace}rule,
.oui-rule,
hr {
    border: 0;
    height: 0;
    border-top: 1px solid map-fetch($border, color, base);
    margin-top: spacer(1) - 1px;
    margin-bottom: spacer(1);

    &--dotted {
        border-top-style: dotted;
    }

    &--dashed {
        border-top-style: dashed;
    }
}
