@use 'sass:map';

@use "@optimizely/design-tokens/dist/scss/colors" as axiom-colors;
@use "./mixins.scss";
@import "../../oui/oui-utils";

.axiom-feedback {
    right: 24px;
    top: 64px;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    display: flex;
    max-width: 600px;
    min-width: 376px;
    padding: 12px 16px;
    position: fixed;
    width: fit-content;
    z-index: map.get($z-index, feedback);

    &__icon {
        padding-right: 16px;
    }

    &__message {
        flex-grow: 1;
    }

    &--bad-news {
        background-color: axiom-colors.$feedback-bad-news-background-color;
        border-color: axiom-colors.$feedback-bad-news-border-color;

        .axiom-feedback__icon {
            color: axiom-colors.$feedback-bad-news-leading-icon-color;
        }
    }

    &--good-news {
        background-color: axiom-colors.$feedback-good-news-background-color;
        border-color: axiom-colors.$feedback-good-news-border-color;

        .axiom-feedback__icon {
            color: axiom-colors.$feedback-good-news-leading-icon-color;
        }
    }

    &--info {
        background-color: axiom-colors.$feedback-info-background-color;
        border-color: axiom-colors.$feedback-info-border-color;

        .axiom-feedback__icon {
            color: axiom-colors.$feedback-info-leading-icon-color;
        }
    }

    &--warning {
        background-color: axiom-colors.$feedback-warning-background-color;
        border-color: axiom-colors.$feedback-warning-border-color;

        .axiom-feedback__icon {
            color: axiom-colors.$feedback-warning-leading-icon-color;
        }
    }

    &--open {
        @include mixins.slide-x-animation(slide-in-top-right, 200%, 0);
        animation: slide-in-top-right 0.5s;
    }

    &--dismissed {
        animation: fade-out 1s forwards;
    }
}

@keyframes fade-out {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
