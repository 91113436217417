@import "../../oui/oui-utils";

$self: '.axiom-navigation';
$navbar-height: 40px;
$light-theme-opacity: 0.5;
$logo-height: 30px;
$title-font-size: 14px;

#{$self},
%#{$namespace}navigation,
.#{$namespace}navigation {
    height: $navbar-height;
    position: relative;
    z-index: 2;

    &::after {
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    &__brand {
        border-right: 1px solid rgba($base-white, 0.5);
        z-index: 3;
    }

    &__actions {
        height: 100%;
        margin-left: auto;
        z-index: 3;
    }

    &__logo {
        height: $logo-height;

        &--mark {
            margin-left: 10px;
            height: 100%;
        }
    }

    &__title {
        font-size: $title-font-size;
        font-weight: 600;
    }

    &--dark {
        &::after {
            background: map-fetch($color, background, brand-dark);
        }

        &#{$self}::before {
            background-color: map-fetch($color, background, brand);
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 20px;
            z-index: 1;
        }

        #{$self}__title {
            color: map-fetch($color, text, white);
        }
    }

    &--light {
        &::after {
            background: map-fetch($color, background, light);
            opacity: $light-theme-opacity;
        }

        #{$self}__title {
            color: map-fetch($color, text, base);
        }
    }
}
