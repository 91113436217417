////
/// Sizing
/// @example[html]
///   <div class="#{$namespace}grid">
///     <div class="#{$namespace}grid__cell width--1-1">
///       <div class="background--faint">1/1</div>
///     </div>
///   </div>
///   <div class="#{$namespace}grid">
///     <div class="#{$namespace}grid__cell width--3-5">
///       <div class="background--faint">3/5</div>
///     </div>
///     <div class="#{$namespace}grid__cell width--1-5">
///       <div class="background--faint">1/5</div>
///     </div>
///     <div class="#{$namespace}grid__cell width--1-5">
///       <div class="background--faint">1/5</div>
///     </div>
///   </div>
////

/// Width Percentages
/// @description Used most frequently in grids or tables for flexible columns.

/// ---

/// Whole

%width--1-1,
.width--1-1 {
    width: 100% !important;
}

/// Halves

%width--1-2,
.width--1-2 {
    width: 50% !important;
}

/// Thirds

%width--1-3,
.width--1-3 {
    width: 33.333% !important;
}

%width--2-3,
.width--2-3 {
    width: 66.666% !important;
}

/// Fourths

%width--1-4,
.width--1-4 {
    width: 25% !important;
}

.width--2-4 {
    @extend %width--1-2;
}

%width--3-4,
.width--3-4 {
    width: 75% !important;
}

/// Fifths

%width--1-5,
.width--1-5 {
    width: 20% !important;
}

%width--2-5,
.width--2-5 {
    width: 40% !important;
}

%width--3-5,
.width--3-5 {
    width: 60% !important;
}

%width--4-5,
.width--4-5 {
    width: 80% !important;
}

/// Sixths

%width--1-6,
.width--1-6 {
    width: 16.666% !important;
}

.width--2-6 {
    @extend %width--1-3;
}

.width--3-6 {
    @extend %width--1-2;
}

.width--4-6 {
    @extend %width--2-3;
}

%width--5-6,
.width--5-6 {
    width: 83.333% !important;
}

/// Eighths

%width--1-8,
.width--1-8 {
    width: 12.5% !important;
}

.width--2-8 {
    @extend %width--1-4;
}

%width--3-8,
.width--3-8 {
    width: 37.5% !important;
}

.width--4-8 {
    @extend %width--1-2;
}

%width--5-8,
.width--5-8 {
    width: 62.5% !important;
}

.width--6-8 {
    @extend %width--3-4;
}

%width--7-8,
.width--7-8 {
    width: 87.5% !important;
}

/// Tenths

%width--1-10,
.width--1-10 {
    width: 10% !important;
}

.width--2-10 {
    @extend %width--1-5;
}

%width--3-10,
.width--3-10 {
    width: 30% !important;
}

.width--4-10 {
    @extend %width--2-5;
}

.width--5-10 {
    @extend %width--1-2;
}

.width--6-10 {
    @extend %width--3-5;
}

%width--7-10,
.width--7-10 {
    width: 70% !important;
}

.width--8-10 {
    @extend %width--4-5;
}

%width--9-10,
.width--9-10 {
    width: 90% !important;
}

/// Twelths

%width--1-12,
.width--1-12 {
    width: 8.333% !important;
}

.width--2-12 {
    @extend %width--1-6;
}

.width--3-12 {
    @extend %width--1-4;
}

.width--4-12 {
    @extend %width--1-3;
}

%width--5-12,
.width--5-12 {
    width: 41.666% !important;
}

.width--6-12 {
    @extend %width--1-2;
}

%width--7-12,
.width--7-12 {
    width: 58.333% !important;
}

.width--8-12 {
    @extend %width--2-3;
}

.width--9-12 {
    @extend %width--3-4;
}

.width--10-12 {
    @extend %width--5-6;
}

%width--11-12,
.width--11-12 {
    width: 91.666% !important;
}

/// Fixed widths

// IE10: The `-ms-flex-preferred-size` values are to overcome any `flex:...`
// values the element may have. Otherwise the width collapses in IE.

.width--50 {
    width: 50px !important;
    -ms-flex-preferred-size: 50px !important;
}

.width--75 {
    width: 75px !important;
    -ms-flex-preferred-size: 75px !important;
}

.width--100 {
    width: 100px !important;
    -ms-flex-preferred-size: 100px !important;
}

.width--150 {
    width: 150px !important;
    -ms-flex-preferred-size: 150px !important;
}

.width--200 {
    width: 200px !important;
    -ms-flex-preferred-size: 200px !important;
}

.width--250 {
    width: 250px !important;
    -ms-flex-preferred-size: 250px !important;
}

.width--300 {
    width: 300px !important;
    -ms-flex-preferred-size: 300px !important;
}

/// Max Width Overrides

.max-width--50 {
    max-width: 50px !important;
}

.max-width--75 {
    max-width: 75px !important;
}

.max-width--100 {
    max-width: 100px !important;
}

.max-width--150 {
    max-width: 150px !important;
}

.max-width--200 {
    max-width: 200px !important;
}

.max-width--250 {
    max-width: 250px !important;
}

.max-width--300 {
    max-width: 300px !important;
}

/// Min Width Overrides

.min-width--50 {
    min-width: 50px !important;
}

.min-width--75 {
    min-width: 75px !important;
}

.min-width--100 {
    min-width: 100px !important;
}

.min-width--150 {
    min-width: 150px !important;
}

.min-width--200 {
    min-width: 200px !important;
}

.min-width--250 {
    min-width: 250px !important;
}

.min-width--300 {
    min-width: 300px !important;
}

/// Height Overrides

.height--1-1 {
    height: 100% !important;
}

.height--50 {
    height: 50px !important;
}

.height--75 {
    height: 75px !important;
}

.height--100 {
    height: 100px !important;
}

.height--150 {
    height: 150px !important;
}

.height--200 {
    height: 200px !important;
}

.height--250 {
    height: 250px !important;
}

.height--300 {
    height: 300px !important;
}

.height--auto {
    height: auto !important;
}

/// Min Height Overrides

.min-height--50 {
    min-height: 50px !important;
}

.min-height--75 {
    min-height: 75px !important;
}

.min-height--100 {
    min-height: 100px !important;
}

.min-height--150 {
    min-height: 150px !important;
}

.min-height--200 {
    min-height: 200px !important;
}

.min-height--250 {
    min-height: 250px !important;
}

.min-height--300 {
    min-height: 300px !important;
}
