////
/// OUI Layout Helper Classes + Responsive Utilities
/// TODO[1]: How to break this up into small, large files
/// TODO[1]: Remove duplicate types of helper classes
////

.order-first {
    order: -1;
}

.order-last {
    order: 13;
}

.order-0 {
    order: 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}

.order-5 {
    order: 5;
}

.order-6 {
    order: 6;
}

.order-7 {
    order: 7;
}

.order-8 {
    order: 8;
}

.order-9 {
    order: 9;
}

.order-10 {
    order: 10;
}

.order-11 {
    order: 11;
}

.order-12 {
    order: 12;
}

.offset-1 {
    margin-left: 8.333333%;
}

.offset-2 {
    margin-left: 16.666667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.333333%;
}

.offset-5 {
    margin-left: 41.666667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.333333%;
}

.offset-8 {
    margin-left: 66.666667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.333333%;
}

.offset-11 {
    margin-left: 91.666667%;
}

@media (min-width: 576px) {
    .col-small {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-small-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-small-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-small-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-small-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-small-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-small-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-small-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-small-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-small-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-small-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-small-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-small-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-small-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-small-first {
        order: -1;
    }
    .order-small-last {
        order: 13;
    }
    .order-small-0 {
        order: 0;
    }
    .order-small-1 {
        order: 1;
    }
    .order-small-2 {
        order: 2;
    }
    .order-small-3 {
        order: 3;
    }
    .order-small-4 {
        order: 4;
    }
    .order-small-5 {
        order: 5;
    }
    .order-small-6 {
        order: 6;
    }
    .order-small-7 {
        order: 7;
    }
    .order-small-8 {
        order: 8;
    }
    .order-small-9 {
        order: 9;
    }
    .order-small-10 {
        order: 10;
    }
    .order-small-11 {
        order: 11;
    }
    .order-small-12 {
        order: 12;
    }
    .offset-small-0 {
        margin-left: 0;
    }
    .offset-small-1 {
        margin-left: 8.333333%;
    }
    .offset-small-2 {
        margin-left: 16.666667%;
    }
    .offset-small-3 {
        margin-left: 25%;
    }
    .offset-small-4 {
        margin-left: 33.333333%;
    }
    .offset-small-5 {
        margin-left: 41.666667%;
    }
    .offset-small-6 {
        margin-left: 50%;
    }
    .offset-small-7 {
        margin-left: 58.333333%;
    }
    .offset-small-8 {
        margin-left: 66.666667%;
    }
    .offset-small-9 {
        margin-left: 75%;
    }
    .offset-small-10 {
        margin-left: 83.333333%;
    }
    .offset-small-11 {
        margin-left: 91.666667%;
    }
}

@media (min-width: 992px) {
    .col-large {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-large-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-large-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-large-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-large-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-large-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-large-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-large-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-large-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-large-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-large-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-large-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-large-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-large-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-large-first {
        order: -1;
    }
    .order-large-last {
        order: 13;
    }
    .order-large-0 {
        order: 0;
    }
    .order-large-1 {
        order: 1;
    }
    .order-large-2 {
        order: 2;
    }
    .order-large-3 {
        order: 3;
    }
    .order-large-4 {
        order: 4;
    }
    .order-large-5 {
        order: 5;
    }
    .order-large-6 {
        order: 6;
    }
    .order-large-7 {
        order: 7;
    }
    .order-large-8 {
        order: 8;
    }
    .order-large-9 {
        order: 9;
    }
    .order-large-10 {
        order: 10;
    }
    .order-large-11 {
        order: 11;
    }
    .order-large-12 {
        order: 12;
    }
    .offset-large-0 {
        margin-left: 0;
    }
    .offset-large-1 {
        margin-left: 8.333333%;
    }
    .offset-large-2 {
        margin-left: 16.666667%;
    }
    .offset-large-3 {
        margin-left: 25%;
    }
    .offset-large-4 {
        margin-left: 33.333333%;
    }
    .offset-large-5 {
        margin-left: 41.666667%;
    }
    .offset-large-6 {
        margin-left: 50%;
    }
    .offset-large-7 {
        margin-left: 58.333333%;
    }
    .offset-large-8 {
        margin-left: 66.666667%;
    }
    .offset-large-9 {
        margin-left: 75%;
    }
    .offset-large-10 {
        margin-left: 83.333333%;
    }
    .offset-large-11 {
        margin-left: 91.666667%;
    }
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .d-small-none {
        display: none !important;
    }
    .d-small-inline {
        display: inline !important;
    }
    .d-small-inline-block {
        display: inline-block !important;
    }
    .d-small-block {
        display: block !important;
    }
    .d-small-table {
        display: table !important;
    }
    .d-small-table-row {
        display: table-row !important;
    }
    .d-small-table-cell {
        display: table-cell !important;
    }
    .d-small-flex {
        display: flex !important;
    }
    .d-small-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-large-none {
        display: none !important;
    }
    .d-large-inline {
        display: inline !important;
    }
    .d-large-inline-block {
        display: inline-block !important;
    }
    .d-large-block {
        display: block !important;
    }
    .d-large-table {
        display: table !important;
    }
    .d-large-table-row {
        display: table-row !important;
    }
    .d-large-table-cell {
        display: table-cell !important;
    }
    .d-large-flex {
        display: flex !important;
    }
    .d-large-inline-flex {
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }
    .d-print-inline {
        display: inline !important;
    }
    .d-print-inline-block {
        display: inline-block !important;
    }
    .d-print-block {
        display: block !important;
    }
    .d-print-table {
        display: table !important;
    }
    .d-print-table-row {
        display: table-row !important;
    }
    .d-print-table-cell {
        display: table-cell !important;
    }
    .d-print-flex {
        display: flex !important;
    }
    .d-print-inline-flex {
        display: inline-flex !important;
    }
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    flex-shrink: 1 !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-center {
    align-content: center !important;
}

.align-content-between {
    align-content: space-between !important;
}

.align-content-around {
    align-content: space-around !important;
}

.align-content-stretch {
    align-content: stretch !important;
}

.align-self-auto {
    align-self: auto !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch {
    align-self: stretch !important;
}

@media (min-width: 576px) {
    .flex-small-row {
        flex-direction: row !important;
    }
    .flex-small-column {
        flex-direction: column !important;
    }
    .flex-small-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-small-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-small-wrap {
        flex-wrap: wrap !important;
    }
    .flex-small-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-small-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-small-fill {
        flex: 1 1 auto !important;
    }
    .flex-small-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-small-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-small-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-small-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-small-start {
        justify-content: flex-start !important;
    }
    .justify-content-small-end {
        justify-content: flex-end !important;
    }
    .justify-content-small-center {
        justify-content: center !important;
    }
    .justify-content-small-between {
        justify-content: space-between !important;
    }
    .justify-content-small-around {
        justify-content: space-around !important;
    }
    .align-items-small-start {
        align-items: flex-start !important;
    }
    .align-items-small-end {
        align-items: flex-end !important;
    }
    .align-items-small-center {
        align-items: center !important;
    }
    .align-items-small-baseline {
        align-items: baseline !important;
    }
    .align-items-small-stretch {
        align-items: stretch !important;
    }
    .align-content-small-start {
        align-content: flex-start !important;
    }
    .align-content-small-end {
        align-content: flex-end !important;
    }
    .align-content-small-center {
        align-content: center !important;
    }
    .align-content-small-between {
        align-content: space-between !important;
    }
    .align-content-small-around {
        align-content: space-around !important;
    }
    .align-content-small-stretch {
        align-content: stretch !important;
    }
    .align-self-small-auto {
        align-self: auto !important;
    }
    .align-self-small-start {
        align-self: flex-start !important;
    }
    .align-self-small-end {
        align-self: flex-end !important;
    }
    .align-self-small-center {
        align-self: center !important;
    }
    .align-self-small-baseline {
        align-self: baseline !important;
    }
    .align-self-small-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    .flex-large-row {
        flex-direction: row !important;
    }
    .flex-large-column {
        flex-direction: column !important;
    }
    .flex-large-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-large-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-large-wrap {
        flex-wrap: wrap !important;
    }
    .flex-large-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-large-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-large-fill {
        flex: 1 1 auto !important;
    }
    .flex-large-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-large-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-large-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-large-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-large-start {
        justify-content: flex-start !important;
    }
    .justify-content-large-end {
        justify-content: flex-end !important;
    }
    .justify-content-large-center {
        justify-content: center !important;
    }
    .justify-content-large-between {
        justify-content: space-between !important;
    }
    .justify-content-large-around {
        justify-content: space-around !important;
    }
    .align-items-large-start {
        align-items: flex-start !important;
    }
    .align-items-large-end {
        align-items: flex-end !important;
    }
    .align-items-large-center {
        align-items: center !important;
    }
    .align-items-large-baseline {
        align-items: baseline !important;
    }
    .align-items-large-stretch {
        align-items: stretch !important;
    }
    .align-content-large-start {
        align-content: flex-start !important;
    }
    .align-content-large-end {
        align-content: flex-end !important;
    }
    .align-content-large-center {
        align-content: center !important;
    }
    .align-content-large-between {
        align-content: space-between !important;
    }
    .align-content-large-around {
        align-content: space-around !important;
    }
    .align-content-large-stretch {
        align-content: stretch !important;
    }
    .align-self-large-auto {
        align-self: auto !important;
    }
    .align-self-large-start {
        align-self: flex-start !important;
    }
    .align-self-large-end {
        align-self: flex-end !important;
    }
    .align-self-large-center {
        align-self: center !important;
    }
    .align-self-large-baseline {
        align-self: baseline !important;
    }
    .align-self-large-stretch {
        align-self: stretch !important;
    }
}
