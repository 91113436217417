////
/// Progress Bar
/// @description Creates a progress bar.
/// @example[html]
///   <div class="#{$namespace}progress">
///     <div class="#{$namespace}progress__bar" style="width: 60%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">60%</div>
///   </div>
/// @example[html]
///   <div class="#{$namespace}progress">
///     <div class="#{$namespace}progress__bar" style="width: 1%;" aria-valuenow="1" aria-valuemin="0" aria-valuemax="100">1%</div>
///   </div>
/// @example[html]
///   <div class="#{$namespace}progress">
///     <div class="#{$namespace}progress__bar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">0%</div>
///   </div>
/// @example[html]
///   <div class="#{$namespace}progress #{$namespace}progress--bad-news">
///     <div class="#{$namespace}progress__bar" style="width: 80%;" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">80%</div>
///   </div>
////

@import "../../oui/oui-utils";

.#{$namespace}progress,
.oui-progress {
    background: map-fetch($color, background, faint);
    height: map-fetch($progress-bar, height);
    border-radius: map-fetch($border, radius, base);
    box-shadow: inset 0 1px map-fetch($shadow, light);
    overflow: hidden;

    &--bad-news {
        .#{$namespace}progress__bar,
        .oui-progress__bar {
            background: map-fetch($color, ui, bad-news);
        }
    }

    &__bar {
        transition: width map-fetch($transition-duration, base);
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        height: 100%;
        background: map-fetch($color, background, brand);
        box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
        color: map-fetch($color, text, white);
        font-size: map-fetch($font, size, milli);
        min-width: map-fetch($progress-bar, bar, min-width);
        max-width: 100%;

        &[aria-valuenow="0"] {
            background: transparent;
            box-shadow: none;
            color: map-fetch($color, text, medium);
        }
    }
}
