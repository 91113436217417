// Do not edit directly
// Generated on Tue, 02 Aug 2022 12:57:01 GMT

$neutral-colors-black: hsla(0, 0%, 0%, 1);
$neutral-colors-white: hsla(0, 0%, 100%, 1);
$blue-dark: hsla(207, 77%, 38%, 1);
$blue-base: hsla(207, 90%, 54%, 1);
$blue-light: hsla(207, 89%, 82%, 1);
$blue-extra-light: hsla(209, 91%, 95%, 1);
$aqua-blue-dark: hsla(187, 100%, 27%, 1);
$aqua-blue-base: hsla(187, 100%, 42%, 1);
$aqua-blue-light: hsla(187, 73%, 77%, 1);
$aqua-blue-extra-light: hsla(189, 72%, 94%, 1);
$green-dark: hsla(122, 39%, 34%, 1);
$green-base: hsla(122, 39%, 49%, 1);
$green-light: hsla(123, 38%, 80%, 1);
$green-extra-light: hsla(120, 38%, 95%, 1);
$yellow-dark: hsla(45, 96%, 43%, 1);
$yellow-base: hsla(45, 100%, 51%, 1);
$yellow-light: hsla(45, 100%, 80%, 1);
$yellow-extra-light: hsla(46, 100%, 95%, 1);
$orange-dark: hsla(36, 100%, 32%, 1);
$orange-base: hsla(36, 100%, 50%, 1);
$orange-light: hsla(36, 100%, 80%, 1);
$orange-extra-light: hsla(36, 100%, 95%, 1);
$red-dark: hsla(0, 67%, 45%, 1);
$red-base: hsla(0, 89%, 57%, 1);
$red-light: hsla(0, 89%, 83%, 1);
$red-extra-light: hsla(0, 91%, 96%, 1);
$pink-dark: hsla(341, 55%, 48%, 1);
$pink-base: hsla(340, 82%, 52%, 1);
$pink-light: hsla(341, 87%, 85%, 1);
$pink-extra-light: hsla(340, 86%, 95%, 1);
$magenta-dark: hsla(311, 94%, 26%, 1);
$magenta-base: hsla(311, 95%, 37%, 1);
$magenta-light: hsla(311, 56%, 75%, 1);
$magenta-extra-light: hsla(310, 56%, 94%, 1);
$purple-dark: hsla(263, 51%, 33%, 1);
$purple-base: hsla(263, 52%, 47%, 1);
$purple-light: hsla(263, 46%, 79%, 1);
$purple-extra-light: hsla(263, 48%, 95%, 1);
$grey-dark: hsla(0, 0%, 44%, 1);
$grey-base: hsla(0, 0%, 62%, 1);
$grey-light: hsla(0, 0%, 85%, 1);
$grey-extra-light: hsla(0, 0%, 96%, 1);
$smoke-dark: hsla(0, 0%, 84%, 1);
$smoke-base: hsla(0, 0%, 93%, 1);
$smoke-light: hsla(0, 0%, 96%, 1);
$smoke-extra-light: hsla(0, 0%, 98%, 1);
$brand-purple-dark: hsla(241, 77%, 12%, 1);
$brand-purple-base: hsla(240, 11%, 65%, 1);
$brand-purple-light: hsla(246, 11%, 82%, 1);
$brand-purple-extra-light: hsla(240, 9%, 96%, 1);
$brand-blue-dark: hsla(227, 100%, 50%, 1);
$brand-blue-base: hsla(227, 100%, 85%, 1);
$brand-blue-light: hsla(227, 100%, 95%, 1);
$brand-blue-extra-light: hsla(226, 100%, 97%, 1);
$tooltip-text-color: #c4c4c4;
$tooltip-background-color: #c4c4c4;
$tile-selected-icon-action-color: #0037ff;
$tile-selected-icon-alert-color: #080736;
$tile-selected-icon-grab-color: #c4c4c4;
$tile-selected-text-label-color: #c4c4c4;
$tile-selected-text-description-color: #c4c4c4;
$tile-selected-text-title-color: #080736;
$tile-selected-border-color: #0037ff;
$tile-selected-background-color: #c4c4c4;
$tile-hover-icon-action-color: #0037ff;
$tile-hover-icon-alert-color: #080736;
$tile-hover-icon-grab-color: #c4c4c4;
$tile-hover-text-label-color: #c4c4c4;
$tile-hover-text-description-color: #c4c4c4;
$tile-hover-text-title-color: #080736;
$tile-hover-border-color: #0037ff;
$tile-hover-background-color: #c4c4c4;
$tile-active-icon-action-color: #0037ff;
$tile-active-icon-alert-color: #080736;
$tile-active-icon-grab-color: #c4c4c4;
$tile-active-text-label-color: #c4c4c4;
$tile-active-text-description-color: #c4c4c4;
$tile-active-text-title-color: #080736;
$tile-active-border-color: #c4c4c4;
$tile-active-background-color: #c4c4c4;
$tabs-selected-text-color: #080736;
$tabs-selected-border-color: #c4c4c4;
$tabs-hover-text-color: #0037ff;
$tabs-disabled-text-color: #c4c4c4;
$tabs-active-text-color: #080736;
$tabs-border-color: #0037ff;
$tabs-background-color: #c4c4c4;
$table-disclose-row-selected-background-color: #c4c4c4;
$table-disclose-row-hover-background-color: #c4c4c4;
$table-disclose-row-link-color: #0037ff;
$table-disclose-row-text-color: #080736;
$table-disclose-header-border-color: #c4c4c4;
$table-disclose-header-text-color: #080736;
$table-disclose-border-color: #c4c4c4;
$table-disclose-icon-color: #080736;
$table-disclose-background-color: #c4c4c4;
$table-row-selected-background-color: #c4c4c4;
$table-row-hover-background-color: #c4c4c4;
$table-row-link-color: #0037ff;
$table-row-text-color: #080736;
$table-header-hover-icon-color: #c4c4c4;
$table-header-hover-text-color: #c4c4c4;
$table-header-focus-icon-color: #c4c4c4;
$table-header-focus-text-color: #c4c4c4;
$table-header-icon-color: #c4c4c4;
$table-header-border-color: #c4c4c4;
$table-header-text-color: #c4c4c4;
$table-border-color: #c4c4c4;
$table-background-color: #c4c4c4;
$summary-bar-content-neutral-text-color: #080736;
$summary-bar-content-good-text-color: #c4c4c4;
$summary-bar-content-bad-text-color: #c4c4c4;
$summary-bar-content-icon-color: #c4c4c4;
$summary-bar-content-text-color: #c4c4c4;
$summary-bar-content-title-color: #c4c4c4;
$summary-bar-header-label-color: #c4c4c4;
$summary-bar-header-title-color: #080736;
$summary-bar-border-color: #c4c4c4;
$steps-not-started-icon-border-color: #c4c4c4;
$steps-not-started-icon-background-color: #c4c4c4;
$steps-not-started-text-color: #080736;
$steps-in-progress-icon-border-color: #0037ff;
$steps-in-progress-icon-background-color: #c4c4c4;
$steps-in-progress-text-color: #0037ff;
$steps-complete-icon-border-color: #0037ff;
$steps-complete-icon-background-color: #0037ff;
$steps-complete-text-color: #080736;
$spinner-highlight-color: #0037ff;
$spinner-border-color: #c4c4c4;
$sidebar-body-text-color: #080736;
$sidebar-header-text-color: #080736;
$sidebar-border-color: #c4c4c4;
$sidebar-background-color: #c4c4c4;
$select-disabled-icon-color: #c4c4c4;
$select-disabled-border-color: #c4c4c4;
$select-disabled-placeholder-color: #c4c4c4;
$select-disabled-optional-color: #c4c4c4;
$select-disabled-required-color: #c4c4c4;
$select-disabled-label-color: #c4c4c4;
$select-hover-icon-color: #c4c4c4;
$select-hover-border-color: #c4c4c4;
$select-hover-placeholder-color: #c4c4c4;
$select-hover-optional-color: #c4c4c4;
$select-hover-required-color: #c4c4c4;
$select-hover-label-color: #c4c4c4;
$select-active-icon-color: #080736;
$select-active-border-color: #c4c4c4;
$select-active-placeholder-color: #c4c4c4;
$select-active-optional-color: #c4c4c4;
$select-active-required-color: #c4c4c4;
$select-active-label-color: #080736;
$radio-button-unchecked-focus-description-color: #080736;
$radio-button-unchecked-focus-label-color: #080736;
$radio-button-unchecked-focus-icon-color: #c4c4c4;
$radio-button-unchecked-focus-background-color: #c4c4c4;
$radio-button-unchecked-hover-description-color: #080736;
$radio-button-unchecked-hover-label-color: #080736;
$radio-button-unchecked-hover-icon-color: #c4c4c4;
$radio-button-unchecked-hover-background-color: #c4c4c4;
$radio-button-unchecked-disabled-description-color: #c4c4c4;
$radio-button-unchecked-disabled-label-color: #c4c4c4;
$radio-button-unchecked-disabled-icon-color: #c4c4c4;
$radio-button-unchecked-disabled-background-color: #c4c4c4;
$radio-button-unchecked-active-description-color: #080736;
$radio-button-unchecked-active-label-color: #080736;
$radio-button-unchecked-active-icon-color: #c4c4c4;
$radio-button-unchecked-active-background-color: #c4c4c4;
$radio-button-checked-focus-description-color: #080736;
$radio-button-checked-focus-label-color: #080736;
$radio-button-checked-focus-icon-color: #0037ff;
$radio-button-checked-focus-background-color: #c4c4c4;
$radio-button-checked-hover-description-color: #080736;
$radio-button-checked-hover-label-color: #080736;
$radio-button-checked-hover-icon-color: #c4c4c4;
$radio-button-checked-hover-background-color: #c4c4c4;
$radio-button-checked-disabled-description-color: #c4c4c4;
$radio-button-checked-disabled-label-color: #c4c4c4;
$radio-button-checked-disabled-icon-color: #c4c4c4;
$radio-button-checked-disabled-background-color: #c4c4c4;
$radio-button-checked-active-description-color: #080736;
$radio-button-checked-active-label-color: #080736;
$radio-button-checked-active-icon-color: #0037ff;
$radio-button-checked-active-background-color: #c4c4c4;
$progress-dots-light-color: #c4c4c4;
$progress-dots-medium-color: #c4c4c4;
$progress-dots-dark-color: #0037ff;
$progress-bar-fill-color: #0037ff;
$progress-bar-background-color: #c4c4c4;
$popover-text-color: #080736;
$popover-title-color: #080736;
$popover-border-color: #c4c4c4;
$popover-background-color: #c4c4c4;
$pill-error-dismissible-icon-color: #c4c4c4;
$pill-error-draggable-icon-color: #c4c4c4;
$pill-error-label-color: #c4c4c4;
$pill-error-background-color: #c4c4c4;
$pill-tertiary-dismissible-icon-color: #c4c4c4;
$pill-tertiary-draggable-icon-color: #c4c4c4;
$pill-tertiary-label-color: #c4c4c4;
$pill-tertiary-background-color: #c4c4c4;
$pill-secondary-dismissible-icon-color: #c4c4c4;
$pill-secondary-draggable-icon-color: #c4c4c4;
$pill-secondary-label-color: #c4c4c4;
$pill-secondary-background-color: #c4c4c4;
$pill-primary-dismissible-icon-color: #c4c4c4;
$pill-primary-draggable-icon-color: #c4c4c4;
$pill-primary-label-color: #c4c4c4;
$pill-primary-background-color: #c4c4c4;
$pill-well-border-color: #c4c4c4;
$pill-well-background-color: #c4c4c4;
$pagination-label-color: #c4c4c4;
$pagination-link-disabled-color: #c4c4c4;
$pagination-link-selected-color: #080736;
$pagination-link-focus-color: #0037ff;
$pagination-link-hover-color: #c4c4c4;
$pagination-link-color: #0037ff;
$nav-bar-copy-right-text-color: #c4c4c4;
$nav-bar-account-focus-text-color: #080736;
$nav-bar-account-hover-text-color: #0037ff;
$nav-bar-account-avatar-background-color: #c4c4c4;
$nav-bar-account-avatar-border-color: #c4c4c4;
$nav-bar-account-text-color: #080736;
$nav-bar-item-name-focus-background-color: #c4c4c4;
$nav-bar-item-name-focus-icon-color: #080736;
$nav-bar-item-name-focus-text-color: #080736;
$nav-bar-item-name-hover-background-color: #c4c4c4;
$nav-bar-item-name-hover-icon-color: #0037ff;
$nav-bar-item-name-hover-text-color: #0037ff;
$nav-bar-item-name-icon-color: #080736;
$nav-bar-item-name-text-color: #080736;
$nav-bar-project-name-color: #c4c4c4;
$nav-bar-divider-color: #c4c4c4;
$nav-bar-border-color: #c4c4c4;
$nav-bar-background-color: #c4c4c4;
$link-reverse-focus-icon-color: #c4c4c4;
$link-reverse-focus-label-color: #c4c4c4;
$link-reverse-hover-icon-color: #c4c4c4;
$link-reverse-hover-label-color: #c4c4c4;
$link-reverse-disabled-icon-color: #c4c4c4;
$link-reverse-disabled-label-color: #c4c4c4;
$link-reverse-icon-color: #c4c4c4;
$link-reverse-label-color: #c4c4c4;
$link-bad-news-focus-icon-color: #080736;
$link-bad-news-focus-label-color: #080736;
$link-bad-news-hover-icon-color: #c4c4c4;
$link-bad-news-hover-label-color: #c4c4c4;
$link-bad-news-disabled-icon-color: #c4c4c4;
$link-bad-news-disabled-label-color: #c4c4c4;
$link-bad-news-icon-color: #080736;
$link-bad-news-label-color: #080736;
$link-muted-focus-icon-color: #c4c4c4;
$link-muted-focus-label-color: #c4c4c4;
$link-muted-hover-icon-color: #0037ff;
$link-muted-hover-label-color: #0037ff;
$link-muted-disabled-icon-color: #c4c4c4;
$link-muted-disabled-label-color: #c4c4c4;
$link-muted-icon-color: #c4c4c4;
$link-muted-label-color: #c4c4c4;
$link-dark-focused-icon-color: #080736;
$link-dark-focused-label-color: #080736;
$link-dark-hover-icon-color: #0037ff;
$link-dark-hover-label-color: #0037ff;
$link-dark-disabled-icon-color: #c4c4c4;
$link-dark-disabled-label-color: #c4c4c4;
$link-dark-icon-color: #080736;
$link-dark-label-color: #080736;
$link-default-focus-icon-color: #0037ff;
$link-default-focus-text-color: #0037ff;
$link-default-hover-icon-color: #c4c4c4;
$link-default-hover-text-color: #c4c4c4;
$link-default-disabled-icon-color: #c4c4c4;
$link-default-disabled-text-color: #c4c4c4;
$link-default-icon-color: #0037ff;
$link-default-text-color: #0037ff;
$input-text-area-disabled-note-color: #c4c4c4;
$input-text-area-disabled-placeholder-color: #c4c4c4;
$input-text-area-disabled-label-color: #c4c4c4;
$input-text-area-disabled-border-color: #c4c4c4;
$input-text-area-disabled-background-color: #c4c4c4;
$input-text-area-error-focus-note-color: #c4c4c4;
$input-text-area-error-focus-placeholder-color: #c4c4c4;
$input-text-area-error-focus-label-color: #c4c4c4;
$input-text-area-error-focus-border-color: #c4c4c4;
$input-text-area-error-focus-background-color: #c4c4c4;
$input-text-area-error-hover-note-color: #c4c4c4;
$input-text-area-error-hover-placeholder-color: #c4c4c4;
$input-text-area-error-hover-label-color: #c4c4c4;
$input-text-area-error-hover-border-color: #c4c4c4;
$input-text-area-error-hover-background-color: #c4c4c4;
$input-text-area-error-active-note-color: #c4c4c4;
$input-text-area-error-active-placeholder-color: #c4c4c4;
$input-text-area-error-active-label-color: #c4c4c4;
$input-text-area-error-active-border-color: #c4c4c4;
$input-text-area-error-active-background-color: #c4c4c4;
$input-text-area-default-focus-note-color: #080736;
$input-text-area-default-focus-placeholder-color: #c4c4c4;
$input-text-area-default-focus-label-color: #080736;
$input-text-area-default-focus-border-color: #c4c4c4;
$input-text-area-default-focus-background-color: #c4c4c4;
$input-text-area-default-hover-note-color: #080736;
$input-text-area-default-hover-placeholder-color: #c4c4c4;
$input-text-area-default-hover-label-color: #080736;
$input-text-area-default-hover-border-color: #c4c4c4;
$input-text-area-default-hover-background-color: #c4c4c4;
$input-text-area-default-active-note-color: #080736;
$input-text-area-default-active-placeholder-color: #c4c4c4;
$input-text-area-default-active-label-color: #080736;
$input-text-area-default-active-border-color: #c4c4c4;
$input-text-area-default-active-background-color: #c4c4c4;
$input-range-handle-dimple-background-color: #c4c4c4;
$input-range-handle-background-color: #c4c4c4;
$input-range-scale-fill-color: #0037ff;
$input-range-scale-background-color: #c4c4c4;
$input-range-border-color: #c4c4c4;
$input-range-label-color: #080736;
$input-error-focus-note-color: #c4c4c4;
$input-error-focus-value-color: #c4c4c4;
$input-error-focus-placeholder-color: #c4c4c4;
$input-error-focus-label-color: #c4c4c4;
$input-error-focus-border-color: #c4c4c4;
$input-error-focus-background-color: #c4c4c4;
$input-error-hover-note-color: #c4c4c4;
$input-error-hover-value-color: #c4c4c4;
$input-error-hover-placeholder-color: #c4c4c4;
$input-error-hover-label-color: #c4c4c4;
$input-error-hover-border-color: #c4c4c4;
$input-error-hover-background-color: #c4c4c4;
$input-error-disabled-note-color: #c4c4c4;
$input-error-disabled-value-color: #c4c4c4;
$input-error-disabled-placeholder-color: #c4c4c4;
$input-error-disabled-label-color: #c4c4c4;
$input-error-disabled-border-color: #c4c4c4;
$input-error-disabled-background-color: #c4c4c4;
$input-error-active-note-color: #c4c4c4;
$input-error-active-value-color: #c4c4c4;
$input-error-active-placeholder-color: #c4c4c4;
$input-error-active-label-color: #c4c4c4;
$input-error-active-border-color: #c4c4c4;
$input-error-active-background-color: #c4c4c4;
$input-default-focus-note-color: #080736;
$input-default-focus-value-color: #080736;
$input-default-focus-placeholder-color: #c4c4c4;
$input-default-focus-label-color: #080736;
$input-default-focus-border-color: #c4c4c4;
$input-default-focus-background-color: #c4c4c4;
$input-default-hover-note-color: #080736;
$input-default-hover-value-color: #080736;
$input-default-hover-placeholder-color: #c4c4c4;
$input-default-hover-label-color: #080736;
$input-default-hover-border-color: #c4c4c4;
$input-default-hover-background-color: #c4c4c4;
$input-default-disabled-note-color: #c4c4c4;
$input-default-disabled-value-color: #c4c4c4;
$input-default-disabled-placeholder-color: #c4c4c4;
$input-default-disabled-label-color: #c4c4c4;
$input-default-disabled-border-color: #c4c4c4;
$input-default-disabled-background-color: #c4c4c4;
$input-default-active-note-color: #080736;
$input-default-active-value-color: #080736;
$input-default-active-placeholder-color: #c4c4c4;
$input-default-active-label-color: #080736;
$input-default-active-border-color: #c4c4c4;
$input-default-active-background-color: #c4c4c4;
$filter-picker-item-focus-description-color: #c4c4c4;
$filter-picker-item-focus-text-color: #0037ff;
$filter-picker-item-focus-icon-color: #0037ff;
$filter-picker-item-hover-description-color: #c4c4c4;
$filter-picker-item-hover-text-color: #0037ff;
$filter-picker-item-hover-icon-color: #0037ff;
$filter-picker-item-icon-color: #0037ff;
$dropdown-error-hover-placeholder-color: #c4c4c4;
$dropdown-error-hover-border-color: rgba(243, 48, 48, 0.7);
$dropdown-error-active-placeholder-color: #c4c4c4;
$dropdown-default-hover-border-color: hsla(227, 100%, 50%, 0.7);
$date-picker-day-range-focus-text-color: #c4c4c4;
$date-picker-day-range-hover-background-color: #c4c4c4;
$date-picker-day-range-selected-text-color: #c4c4c4;
$date-picker-day-focus-text-color: #c4c4c4;
$date-picker-day-hover-background-color: #c4c4c4;
$date-picker-day-selected-text-color: #c4c4c4;
$date-picker-month-title-color: #080736;
$button-underline-pressed-label-color: #c4c4c4;
$button-underline-pressed-border-bottom-color: #c4c4c4;
$button-underline-pressed-background-color: #c4c4c4;
$button-underline-focus-label-color: #c4c4c4;
$button-underline-focus-border-bottom-color: #c4c4c4;
$button-underline-focus-background-color: #c4c4c4;
$button-underline-hover-label-color: #c4c4c4;
$button-underline-hover-border-bottom-color: #c4c4c4;
$button-underline-hover-background-color: #c4c4c4;
$button-danger-hover-border-color: hsla(0, 89%, 57%, 0.2);
$button-danger-hover-background-color: hsla(0, 89%, 57%, 0.8);
$button-highlight-hover-border-color: hsla(227, 100%, 50%, 0.2);
$button-highlight-hover-background-color: hsla(227, 100%, 50%, 0.8);
$butterbar-border-color: #c4c4c4;
$butterbar-background-color: #c4c4c4;
$avatar-icon-color: #c4c4c4;
$avatar-border-color: #c4c4c4;
$avatar-background-color: #c4c4c4;
$editable-dropdown-error-hover-border-color: $red-light;
$editable-dropdown-static-hover-border-color: $brand-blue-base;
$alert-good-news-border-color: $green-base;
$alert-good-news-background-color: $green-extra-light;
$alert-warning-border-color: $yellow-base;
$alert-warning-background-color: $yellow-extra-light;
$alert-bad-news-background-color: $red-extra-light;
$alert-brand-border-color: $brand-blue-base;
$alert-brand-background-color: $brand-blue-extra-light;
$dropdown-menu-list-item-unselected-focus-background-color: $brand-blue-light;
$dropdown-menu-list-item-unselected-disabled-icon-color: $grey-dark;
$dropdown-menu-list-item-unselected-hover-background-color: $brand-blue-extra-light;
$search-picker-hover-border-color: $brand-blue-base;
$list-hierarchical-selected-background-color: $brand-blue-extra-light;
$list-hierarchical-hover-background-color: $brand-blue-extra-light;
$feedback-bad-news-leading-icon-color: $red-base;
$feedback-bad-news-border-color: $red-base;
$feedback-bad-news-background-color: $red-extra-light;
$feedback-warning-leading-icon-color: $orange-base;
$feedback-warning-border-color: $orange-base;
$feedback-warning-background-color: $orange-extra-light;
$feedback-good-news-leading-icon-color: $green-base;
$feedback-good-news-border-color: $green-base;
$feedback-good-news-background-color: $green-extra-light;
$feedback-info-leading-icon-color: $brand-blue-dark;
$feedback-info-border-color: $brand-blue-base;
$feedback-info-background-color: $brand-blue-extra-light;
$switch-off-disabled-background-color: $grey-light;
$switch-off-active-icon-color: $neutral-colors-white;
$switch-off-active-background-color: $grey-dark;
$switch-on-disabled-background-color: $grey-light;
$switch-on-active-icon-color: $neutral-colors-white;
$icon-disabled-color: $grey-base;
$icon-base-color: $brand-purple-dark;
$dropdown-default-focus-outline-color: $brand-blue-base;
$checkbox-unchecked-focus-background-color: $brand-blue-light;
$checkbox-checked-focus-icon-color: $neutral-colors-white;
$checkbox-checked-hover-background-color: $brand-blue-light;
$checkbox-checked-active-icon-color: $neutral-colors-white;
$button-toggle-pressed-hover-shadow-color: $brand-blue-base;
$button-toggle-pressed-hover-background-color: $brand-blue-light;
$button-toggle-pressed-shadow-color: $brand-blue-base;
$button-toggle-pressed-border-color: $brand-blue-base;
$button-toggle-pressed-background-color: $brand-blue-light;
$button-toggle-focus-border-color: $brand-blue-base;
$button-toggle-hover-border-color: $brand-blue-base;
$button-plain-focus-outline-color: $brand-blue-base;
$button-plain-hover-border-color: $brand-blue-base;
$button-danger-outline-focus-outline-color: $red-light;
$button-danger-outline-hover-border-color: $red-light;
$button-danger-pressed-label-color: $neutral-colors-white;
$button-danger-focus-outline-color: $red-light;
$button-danger-focus-label-color: $neutral-colors-white;
$button-danger-hover-label-color: $neutral-colors-white;
$button-danger-active-label-color: $neutral-colors-white;
$button-outline-focus-outline-color: $brand-blue-base;
$button-outline-hover-border-color: $brand-blue-base;
$button-highlight-pressed-label-color: $neutral-colors-white;
$button-highlight-focus-outline-color: $brand-blue-base;
$button-highlight-focus-label-color: $neutral-colors-white;
$button-highlight-hover-label-color: $neutral-colors-white;
$button-highlight-active-label-color: $neutral-colors-white;
$button-default-focus-outline-color: $brand-blue-base;
$button-default-hover-border-color: $brand-blue-base;
$border-disabled-color: $grey-light;
$border-divider-color: $smoke-dark;
$border-user-input-color: $grey-base;
$badge-beta-label-color: $neutral-colors-white;
$badge-beta-background-color: $blue-dark;
$badge-new-label-color: $neutral-colors-white;
$badge-new-background-color: $blue-dark;
$badge-primary-label-color: $neutral-colors-white;
$badge-bad-label-color: $neutral-colors-white;
$badge-bad-background-color: $red-dark;
$badge-live-label-color: $neutral-colors-white;
$badge-live-background-color: $green-dark;
$badge-draft-label-color: $neutral-colors-white;
$badge-draft-background-color: $orange-base;
$badge-default-label-color: $neutral-colors-white;
$badge-default-background-color: $grey-dark;
$brand-text-disabled-color: $grey-dark;
$brand-text-secondary-color: $grey-dark;
$brand-text-primary-color: $brand-purple-dark;
$brand-background-disabled-color: $smoke-light;
$brand-background-secondary-color: $smoke-extra-light;
$brand-background-primary-color: $neutral-colors-white;
$brand-error-color: $red-base;
$brand-primary-color: $brand-blue-dark;
$editable-dropdown-error-focus-note-color: $brand-error-color;
$editable-dropdown-error-focus-icon-color: $brand-error-color;
$editable-dropdown-error-focus-placeholder-color: $brand-error-color;
$editable-dropdown-error-focus-label-color: $brand-error-color;
$editable-dropdown-error-focus-border-color: $brand-error-color;
$editable-dropdown-error-focus-background-color: $brand-background-primary-color;
$editable-dropdown-error-hover-note-color: $brand-error-color;
$editable-dropdown-error-hover-icon-color: $brand-error-color;
$editable-dropdown-error-hover-placeholder-color: $brand-error-color;
$editable-dropdown-error-hover-label-color: $brand-error-color;
$editable-dropdown-error-hover-background-color: $brand-background-primary-color;
$editable-dropdown-error-note-color: $brand-error-color;
$editable-dropdown-error-icon-color: $brand-error-color;
$editable-dropdown-error-placeholder-color: $brand-error-color;
$editable-dropdown-error-label-color: $brand-error-color;
$editable-dropdown-error-border-color: $brand-error-color;
$editable-dropdown-error-background-color: $brand-background-primary-color;
$editable-dropdown-disabled-icon-color: $icon-disabled-color;
$editable-dropdown-disabled-placeholder-color: $brand-text-disabled-color;
$editable-dropdown-disabled-label-color: $brand-text-disabled-color;
$editable-dropdown-disabled-border-color: $border-disabled-color;
$editable-dropdown-disabled-background-color: $brand-background-disabled-color;
$editable-dropdown-static-focus-icon-color: $brand-primary-color;
$editable-dropdown-static-focus-placeholder-color: $brand-primary-color;
$editable-dropdown-static-focus-label-color: $brand-text-primary-color;
$editable-dropdown-static-focus-border-color: $brand-primary-color;
$editable-dropdown-static-focus-background-color: $brand-background-primary-color;
$editable-dropdown-static-hover-icon-color: $brand-primary-color;
$editable-dropdown-static-hover-placeholder-color: $brand-primary-color;
$editable-dropdown-static-hover-label-color: $brand-text-primary-color;
$editable-dropdown-static-hover-background-color: $brand-background-primary-color;
$editable-dropdown-static-icon-color: $brand-primary-color;
$editable-dropdown-static-placeholder-color: $brand-primary-color;
$editable-dropdown-static-label-color: $brand-text-primary-color;
$editable-dropdown-static-border-color: $brand-primary-color;
$editable-dropdown-static-background-color: $brand-background-primary-color;
$alert-good-news-icon-color: $icon-base-color;
$alert-warning-icon-color: $icon-base-color;
$alert-bad-news-icon-color: $icon-base-color;
$alert-bad-news-border-color: $brand-error-color;
$alert-brand-icon-color: $icon-base-color;
$alert-brand-text-color: $brand-text-primary-color;
$file-upload-pressed-note-color: $brand-text-primary-color;
$file-upload-pressed-label-color: $brand-text-primary-color;
$file-upload-pressed-title-color: $brand-text-primary-color;
$file-upload-focus-button-outline-color: $button-outline-focus-outline-color;
$file-upload-focus-note-color: $brand-text-primary-color;
$file-upload-focus-label-color: $brand-text-primary-color;
$file-upload-focus-title-color: $brand-text-primary-color;
$file-upload-hover-button-border-color: $button-outline-hover-border-color;
$file-upload-hover-note-color: $brand-text-primary-color;
$file-upload-hover-label-color: $brand-text-primary-color;
$file-upload-hover-title-color: $brand-text-primary-color;
$file-upload-disabled-note-color: $brand-text-disabled-color;
$file-upload-disabled-label-color: $brand-text-disabled-color;
$file-upload-disabled-title-color: $brand-text-disabled-color;
$file-upload-active-note-color: $brand-text-secondary-color;
$file-upload-active-label-color: $brand-text-primary-color;
$file-upload-active-title-color: $brand-text-primary-color;
$dropdown-menu-list-item-supportive-group-header-text-color: $brand-text-secondary-color;
$dropdown-menu-list-item-unselected-focus-description: $brand-primary-color;
$dropdown-menu-list-item-unselected-focus-icon-color: $brand-primary-color;
$dropdown-menu-list-item-unselected-focus-text-color: $brand-primary-color;
$dropdown-menu-list-item-unselected-disabled-description: $brand-text-disabled-color;
$dropdown-menu-list-item-unselected-disabled-text-color: $brand-text-disabled-color;
$dropdown-menu-list-item-unselected-disabled-background-color: $brand-background-disabled-color;
$dropdown-menu-list-item-unselected-hover-description: $brand-primary-color;
$dropdown-menu-list-item-unselected-hover-icon-color: $brand-primary-color;
$dropdown-menu-list-item-unselected-hover-text-color: $brand-primary-color;
$dropdown-menu-list-item-unselected-default-description: $brand-text-secondary-color;
$dropdown-menu-list-item-unselected-default-icon-color: $brand-primary-color;
$dropdown-menu-list-item-unselected-default-text-color: $brand-primary-color;
$dropdown-menu-list-item-unselected-default-background-color: $brand-background-primary-color;
$dropdown-menu-list-item-selected-disable-description: $brand-text-disabled-color;
$dropdown-menu-list-item-selected-disable-icon-color: $brand-text-disabled-color;
$dropdown-menu-list-item-selected-disable-background-color: $brand-background-disabled-color;
$dropdown-menu-list-item-selected-disable-text-color: $brand-text-disabled-color;
$dropdown-menu-list-item-selected-default-description: $brand-text-secondary-color;
$dropdown-menu-list-item-selected-default-icon-color: $brand-text-primary-color;
$dropdown-menu-list-item-selected-default-background-color: $brand-background-primary-color;
$dropdown-menu-list-item-selected-default-text-color: $brand-text-primary-color;
$dropdown-menu-divider-color: $border-divider-color;
$dropdown-menu-background-color: $brand-background-primary-color;
$search-picker-selected-icon-color: $brand-primary-color;
$search-picker-selected-value-color: $brand-primary-color;
$search-picker-selected-placeholder-color: $brand-primary-color;
$search-picker-selected-border-color: $brand-primary-color;
$search-picker-selected-background-color: $brand-background-primary-color;
$search-picker-focus-icon-color: $brand-primary-color;
$search-picker-focus-value-color: $brand-primary-color;
$search-picker-focus-placeholder-color: $brand-primary-color;
$search-picker-focus-border-color: $brand-primary-color;
$search-picker-focus-background-color: $brand-background-primary-color;
$search-picker-hover-icon-color: $brand-primary-color;
$search-picker-hover-value-color: $brand-primary-color;
$search-picker-hover-placeholder-color: $brand-primary-color;
$search-picker-hover-background-color: $brand-background-primary-color;
$search-picker-disabled-icon-color: $icon-disabled-color;
$search-picker-disabled-value-color: $brand-text-disabled-color;
$search-picker-disabled-placeholder-color: $brand-text-disabled-color;
$search-picker-disabled-border-color: $border-disabled-color;
$search-picker-disabled-background-color: $brand-background-disabled-color;
$search-picker-active-icon-color: $icon-base-color;
$search-picker-active-value-color: $brand-text-primary-color;
$search-picker-active-placeholder-color: $brand-text-secondary-color;
$search-picker-active-border-color: $border-user-input-color;
$search-picker-active-background-color: $brand-background-primary-color;
$list-hierarchical-selected-menu-icon-color: $brand-primary-color;
$list-hierarchical-selected-text-label-color: $brand-text-primary-color;
$list-hierarchical-selected-item-icon-color: $icon-base-color;
$list-hierarchical-selected-arrow-icon-color: $icon-base-color;
$list-hierarchical-hover-menu-icon-color: $brand-primary-color;
$list-hierarchical-hover-text-label-color: $brand-primary-color;
$list-hierarchical-hover-item-icon-color: $brand-primary-color;
$list-hierarchical-hover-arrow-icon-color: $brand-primary-color;
$list-hierarchical-text-label-color: $brand-text-primary-color;
$list-hierarchical-item-icon-color: $icon-base-color;
$list-hierarchical-arrow-icon-color: $icon-base-color;
$list-hierarchical-background-color: $brand-background-primary-color;
$feedback-bad-news-dismiss-icon-color: $icon-base-color;
$feedback-bad-news-text-color: $brand-text-primary-color;
$feedback-warning-dismiss-icon-color: $icon-base-color;
$feedback-warning-text-color: $brand-text-primary-color;
$feedback-good-news-dismiss-icon-color: $icon-base-color;
$feedback-good-news-text-color: $brand-text-primary-color;
$feedback-info-dismiss-icon-color: $icon-base-color;
$feedback-info-text-color: $brand-text-primary-color;
$switch-off-disabled-icon-color: $icon-disabled-color;
$switch-on-disabled-icon-color: $icon-disabled-color;
$switch-on-active-background-color: $brand-primary-color;
$footer-border-color: $border-divider-color;
$footer-background-color: $brand-background-secondary-color;
$filter-picker-item-description-text-color: $brand-text-secondary-color;
$filter-picker-item-text-color: $brand-primary-color;
$filter-picker-group-header-text-color: $brand-text-secondary-color;
$filter-picker-search-text-color: $brand-text-secondary-color;
$filter-picker-search-icon-color: $icon-base-color;
$filter-picker-background-color: $brand-background-primary-color;
$filter-picker-border-color: $border-divider-color;
$empty-state-description-color: $brand-text-secondary-color;
$empty-state-title-color: $brand-text-primary-color;
$dropdown-filter-error-icon-color: $brand-error-color;
$dropdown-filter-error-placeholder-color: $brand-error-color;
$dropdown-filter-error-label-color: $brand-error-color;
$dropdown-filter-disabled-icon-color: $brand-text-disabled-color;
$dropdown-filter-disabled-placeholder-color: $brand-text-disabled-color;
$dropdown-filter-disabled-label-color: $brand-text-disabled-color;
$dropdown-filter-focus-border-color: $button-default-hover-border-color;
$dropdown-filter-focus-icon-color: $brand-primary-color;
$dropdown-filter-focus-placeholder-color: $brand-primary-color;
$dropdown-filter-focus-label-color: $brand-text-secondary-color;
$dropdown-filter-hover-border-color: $button-default-focus-outline-color;
$dropdown-filter-hover-icon-color: $brand-primary-color;
$dropdown-filter-hover-placeholder-color: $brand-primary-color;
$dropdown-filter-hover-label-color: $brand-text-secondary-color;
$dropdown-filter-active-icon-color: $brand-primary-color;
$dropdown-filter-active-placeholder-color: $brand-text-primary-color;
$dropdown-filter-active-label-color: $brand-text-secondary-color;
$dropdown-error-hover-required-mark: $brand-error-color;
$dropdown-error-hover-icon-color: $brand-error-color;
$dropdown-error-hover-note-color: $brand-error-color;
$dropdown-error-hover-label-color: $brand-error-color;
$dropdown-error-hover-background-color: $brand-background-primary-color;
$dropdown-error-active-required-mark: $brand-error-color;
$dropdown-error-active-icon-color: $brand-error-color;
$dropdown-error-active-note-color: $brand-error-color;
$dropdown-error-active-label-color: $brand-error-color;
$dropdown-error-active-border-color: $brand-error-color;
$dropdown-error-active-background-color: $brand-background-primary-color;
$dropdown-default-focus-required-mark: $brand-error-color;
$dropdown-default-focus-icon-color: $brand-primary-color;
$dropdown-default-focus-note-color: $brand-text-primary-color;
$dropdown-default-focus-placeholder-color: $brand-primary-color;
$dropdown-default-focus-label-color: $brand-text-primary-color;
$dropdown-default-focus-border-color: $brand-primary-color;
$dropdown-default-focus-background-color: $brand-background-primary-color;
$dropdown-default-hover-required-mark: $brand-error-color;
$dropdown-default-hover-icon-color: $brand-primary-color;
$dropdown-default-hover-note-color: $brand-text-primary-color;
$dropdown-default-hover-placeholder-color: $brand-primary-color;
$dropdown-default-hover-label-color: $brand-text-primary-color;
$dropdown-default-hover-background-color: $brand-background-primary-color;
$dropdown-default-disabled-required-mark: $brand-text-disabled-color;
$dropdown-default-disabled-icon-color: $icon-disabled-color;
$dropdown-default-disabled-note-color: $brand-text-disabled-color;
$dropdown-default-disabled-placeholder-color: $brand-text-disabled-color;
$dropdown-default-disabled-label-color: $brand-text-disabled-color;
$dropdown-default-disabled-border-color: $border-disabled-color;
$dropdown-default-disabled-background-color: $brand-background-disabled-color;
$dropdown-default-active-required-mark: $brand-error-color;
$dropdown-default-active-icon-color: $brand-primary-color;
$dropdown-default-active-note-color: $brand-text-primary-color;
$dropdown-default-active-placeholder-color: $brand-primary-color;
$dropdown-default-active-label-color: $brand-text-primary-color;
$dropdown-default-active-border-color: $brand-primary-color;
$dropdown-default-active-background-color: $brand-background-primary-color;
$disclose-title-focus-color: $brand-text-primary-color;
$disclose-title-hover-color: $brand-primary-color;
$disclose-title-color: $brand-text-primary-color;
$disclose-icon-color: $icon-base-color;
$dialog-footer-background-color: $brand-background-secondary-color;
$dialog-footer-divider-color: $border-divider-color;
$dialog-body-text-color: $brand-text-primary-color;
$dialog-body-subtitle-color: $brand-text-primary-color;
$dialog-header-icon-color: $brand-text-primary-color;
$dialog-header-title-color: $brand-text-primary-color;
$dialog-background-color: $brand-background-primary-color;
$date-picker-disabled-text-color: $brand-text-disabled-color;
$date-picker-day-range-focus-background-color: $brand-primary-color;
$date-picker-day-range-hover-text-color: $brand-text-primary-color;
$date-picker-day-range-selected-background-color: $brand-primary-color;
$date-picker-day-focus-background-color: $brand-primary-color;
$date-picker-day-hover-text-color: $brand-text-primary-color;
$date-picker-day-selected-background-color: $brand-primary-color;
$date-picker-week-title-color: $brand-text-secondary-color;
$date-picker-text-color: $brand-text-primary-color;
$date-picker-icon-color: $icon-base-color;
$date-picker-background-color: $brand-background-primary-color;
$date-picker-border-color: $border-divider-color;
$code-number-border-color: $border-divider-color;
$code-number-background-color: $brand-background-secondary-color;
$code-number-text-color: $brand-text-secondary-color;
$code-background-color: $brand-background-primary-color;
$code-text-color: $brand-text-primary-color;
$checkbox-unchecked-focus-description-color: $brand-text-primary-color;
$checkbox-unchecked-focus-label-color: $brand-text-primary-color;
$checkbox-unchecked-focus-border-color: $brand-primary-color;
$checkbox-unchecked-hover-description-color: $brand-text-primary-color;
$checkbox-unchecked-hover-label-color: $brand-text-primary-color;
$checkbox-unchecked-hover-border-color: $brand-primary-color;
$checkbox-unchecked-hover-background-color: $brand-background-primary-color;
$checkbox-unchecked-disabled-description-color: $brand-text-disabled-color;
$checkbox-unchecked-disabled-label-color: $brand-text-disabled-color;
$checkbox-unchecked-disabled-border-color: $border-disabled-color;
$checkbox-unchecked-disabled-background-color: $brand-background-disabled-color;
$checkbox-unchecked-active-description-color: $brand-text-primary-color;
$checkbox-unchecked-active-label-color: $brand-text-primary-color;
$checkbox-unchecked-active-border-color: $border-user-input-color;
$checkbox-unchecked-active-background-color: $brand-background-primary-color;
$checkbox-checked-focus-border-color: $brand-primary-color;
$checkbox-checked-focus-description-color: $brand-text-primary-color;
$checkbox-checked-focus-label-color: $brand-text-primary-color;
$checkbox-checked-focus-background-color: $brand-primary-color;
$checkbox-checked-hover-border-color: $brand-primary-color;
$checkbox-checked-hover-description-color: $brand-text-primary-color;
$checkbox-checked-hover-label-color: $brand-text-primary-color;
$checkbox-checked-hover-icon-color: $brand-primary-color;
$checkbox-checked-disabled-border-color: $border-disabled-color;
$checkbox-checked-disabled-description-color: $brand-text-disabled-color;
$checkbox-checked-disabled-label-color: $brand-text-disabled-color;
$checkbox-checked-disabled-icon-color: $icon-disabled-color;
$checkbox-checked-disabled-background-color: $brand-background-disabled-color;
$checkbox-checked-active-border-color: $brand-primary-color;
$checkbox-checked-active-description-color: $brand-text-primary-color;
$checkbox-checked-active-label-color: $brand-text-primary-color;
$checkbox-checked-active-background-color: $brand-primary-color;
$card-body-text-color: $brand-text-primary-color;
$card-header-divider-color: $border-divider-color;
$card-header-icon-color: $icon-base-color;
$card-header-title-color: $brand-text-primary-color;
$card-border-color: $border-divider-color;
$card-background-color: $brand-background-primary-color;
$button-toggle-pressed-hover-icon-color: $brand-primary-color;
$button-toggle-pressed-hover-label-color: $brand-primary-color;
$button-toggle-pressed-hover-border-color: $brand-primary-color;
$button-toggle-static-icon-color: $icon-base-color;
$button-toggle-static-label-color: $brand-text-primary-color;
$button-toggle-static-border-color: $brand-background-primary-color;
$button-toggle-static-background-color: $brand-background-primary-color;
$button-toggle-disabled-icon-color: $brand-text-disabled-color;
$button-toggle-disabled-label-color: $brand-text-disabled-color;
$button-toggle-disabled-border-color: $border-disabled-color;
$button-toggle-disabled-background-color: $brand-background-disabled-color;
$button-toggle-pressed-icon-color: $brand-primary-color;
$button-toggle-pressed-label-color: $brand-primary-color;
$button-toggle-focus-icon-color: $brand-primary-color;
$button-toggle-focus-label-color: $brand-primary-color;
$button-toggle-focus-background-color: $brand-background-primary-color;
$button-toggle-hover-icon-color: $brand-primary-color;
$button-toggle-hover-label-color: $brand-primary-color;
$button-toggle-hover-background-color: $brand-background-primary-color;
$button-underline-disabled-label-color: $brand-text-disabled-color;
$button-underline-disabled-border-bottom-color: $border-disabled-color;
$button-underline-disabled-background-color: $brand-background-disabled-color;
$button-underline-active-label-color: $brand-text-primary-color;
$button-underline-active-bottom-border-color: $border-user-input-color;
$button-underline-active-background-color: $brand-background-primary-color;
$button-plain-disabled-label-color: $brand-text-disabled-color;
$button-plain-disabled-border-color: $border-disabled-color;
$button-plain-disabled-background-color: $brand-background-disabled-color;
$button-plain-pressed-label-color: $brand-primary-color;
$button-plain-pressed-border-color: $brand-primary-color;
$button-plain-pressed-background-color: $brand-background-primary-color;
$button-plain-focus-label-color: $brand-primary-color;
$button-plain-focus-border-color: $brand-primary-color;
$button-plain-hover-label-color: $brand-primary-color;
$button-plain-active-label-color: $brand-text-primary-color;
$button-danger-outline-disabled-label-color: $brand-text-disabled-color;
$button-danger-outline-disabled-border-color: $border-disabled-color;
$button-danger-outline-disabled-background-color: $brand-background-disabled-color;
$button-danger-outline-pressed-label-color: $brand-error-color;
$button-danger-outline-pressed-border-color: $brand-error-color;
$button-danger-outline-pressed-background-color: $brand-background-primary-color;
$button-danger-outline-focus-label-color: $brand-error-color;
$button-danger-outline-focus-border-color: $brand-error-color;
$button-danger-outline-focus-background-color: $brand-background-primary-color;
$button-danger-outline-hover-label-color: $brand-error-color;
$button-danger-outline-hover-background-color: $brand-background-primary-color;
$button-danger-outline-active-label-color: $brand-error-color;
$button-danger-outline-active-border-color: $brand-error-color;
$button-danger-outline-active-background-color: $brand-background-primary-color;
$button-danger-disabled-label-color: $brand-text-disabled-color;
$button-danger-disabled-border-color: $border-disabled-color;
$button-danger-disabled-background-color: $brand-background-disabled-color;
$button-danger-pressed-border-color: $brand-error-color;
$button-danger-pressed-background-color: $brand-error-color;
$button-danger-focus-border-color: $brand-error-color;
$button-danger-focus-background-color: $brand-error-color;
$button-danger-active-border-color: $brand-error-color;
$button-danger-active-background-color: $brand-error-color;
$button-outline-disabled-label-color: $brand-text-disabled-color;
$button-outline-disabled-border-color: $border-disabled-color;
$button-outline-disabled-background-color: $brand-background-disabled-color;
$button-outline-pressed-label-color: $brand-primary-color;
$button-outline-pressed-border-color: $brand-primary-color;
$button-outline-pressed-background-color: $brand-background-primary-color;
$button-outline-focus-label-color: $brand-primary-color;
$button-outline-focus-border-color: $brand-primary-color;
$button-outline-focus-background-color: $brand-background-primary-color;
$button-outline-hover-label-color: $brand-primary-color;
$button-outline-hover-background-color: $brand-background-primary-color;
$button-outline-active-label-color: $brand-primary-color;
$button-outline-active-border-color: $brand-primary-color;
$button-outline-active-background-color: $brand-background-primary-color;
$button-highlight-disabled-label-color: $brand-text-disabled-color;
$button-highlight-disabled-border-color: $border-disabled-color;
$button-highlight-disabled-background-color: $brand-background-disabled-color;
$button-highlight-pressed-border-color: $brand-primary-color;
$button-highlight-pressed-background-color: $brand-primary-color;
$button-highlight-focus-border-color: $brand-primary-color;
$button-highlight-focus-background-color: $brand-primary-color;
$button-highlight-active-border-color: $brand-primary-color;
$button-highlight-active-background-color: $brand-primary-color;
$button-default-disabled-label-color: $brand-text-disabled-color;
$button-default-disabled-border-color: $border-disabled-color;
$button-default-disabled-background-color: $brand-background-disabled-color;
$button-default-pressed-label-color: $brand-text-primary-color;
$button-default-pressed-border-color: $border-user-input-color;
$button-default-pressed-background-color: $brand-background-primary-color;
$button-default-focus-label-color: $brand-primary-color;
$button-default-focus-border-color: $brand-primary-color;
$button-default-focus-background-color: $brand-background-primary-color;
$button-default-hover-label-color: $brand-primary-color;
$button-default-hover-background-color: $brand-background-primary-color;
$button-default-active-label-color: $brand-text-primary-color;
$button-default-active-border-color: $border-user-input-color;
$button-default-active-background-color: $brand-background-primary-color;
$butterbar-text-color: $brand-text-primary-color;
$block-list-text-description-color: $brand-text-secondary-color;
$block-list-text-item-color: $brand-text-primary-color;
$block-list-text-header-link-color: $brand-primary-color;
$block-list-text-header-color: $brand-text-secondary-color;
$block-list-background-color: $brand-background-primary-color;
$block-list-divider-color: $border-divider-color;
$block-list-border-color: $border-user-input-color;
$badge-primary-background-color: $brand-primary-color;
$badge-plain-label-color: $brand-text-secondary-color;
$alert-good-news-text-color: $alert-brand-text-color;
$alert-warning-text-color: $alert-brand-text-color;
$alert-bad-news-text-color: $alert-brand-text-color;
$file-upload-pressed-button-label-color: $button-outline-pressed-label-color;
$file-upload-pressed-button-background-color: $button-outline-pressed-background-color;
$file-upload-pressed-button-border-color: $button-outline-pressed-border-color;
$file-upload-focus-button-label-color: $button-outline-focus-label-color;
$file-upload-focus-button-background-color: $button-outline-focus-background-color;
$file-upload-focus-button-border-color: $button-outline-focus-border-color;
$file-upload-hover-button-label-color: $button-outline-hover-label-color;
$file-upload-hover-button-background-color: $button-outline-hover-background-color;
$file-upload-disabled-button-label-color: $button-outline-disabled-label-color;
$file-upload-disabled-button-background-color: $button-outline-disabled-background-color;
$file-upload-disabled-button-border-color: $button-outline-disabled-border-color;
$file-upload-active-button-label-color: $button-outline-active-label-color;
$file-upload-active-button-background-color: $button-outline-active-background-color;
$file-upload-active-button-border-color: $button-outline-active-border-color;
