.activation-status-buttons {
    padding-bottom: 3px !important;
}

.status-label {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.activation-status-icon {
    margin-left: 3px;
}

.activation-status-label {
    font-weight: 530;
}
