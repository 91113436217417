.filter-dropdown {
    width: 100%;
    position: relative;

    &__dropdown-selection {
        padding-bottom: 12px;
    }

    &__search-list-first-item {
        padding-top: 50px; // account for the space the search input takes up.
    }
}
