////
/// Code
/// @description Monospace code snippets, both inline and multiline.
/// @example[html] Inline code
///   Text can have <code class="oui-code">code snippets</code> in a sentence.
/// @example[html] Multiline code
///   <pre class="oui-pre"><code>&lt;!DOCTYPE html&gt;
///   &lt;html&gt;
///     &lt;body&gt;
///
///       &lt;h1&gt;My First Heading&lt;/h1&gt;
///
///       &lt;p&gt;My first paragraph.&lt;/p&gt;
///
///     &lt;/body&gt;
///   &lt;/html&gt;</code></pre>
////

@import "../../oui/oui-utils";

code {
    font-family: map-fetch($font, family, monospace);
    // Bump the monospace font size down a bit because it renders larger than the
    // default body text.
    font-size: map-fetch($font, size, base) - 1;
}

.#{$namespace}pre,
.oui-pre {
    white-space: pre;
}

.oui-pre-wrap {
    white-space: pre-wrap;
}

.#{$namespace}pre,
.oui-pre,
.oui-pre-wrap {
    margin-bottom: spacer(1);

    code {
        padding: spacer(1);
        background: map-fetch($color, background, faint);
        border-radius: map-fetch($border, radius, base);
        overflow-x: auto;
        display: block;
    }
}

.#{$namespace}code,
.oui-code {
    background: map-fetch($color, background, faint);
    border-radius: map-fetch($border, radius, base);
    border: 1px solid map-fetch($border, color, base);
    padding: 1px 3px;
}

.oui-code__container {
    overflow-wrap: break-word;
    background-color: map-fetch($color, background, faint);
    border: 1px solid map-fetch($border, color, base);
    border-radius: map-fetch($border, radius, base);

    .oui-code__offset-content {
        // 5px offset + 48px for copy button + 2px buffer
        margin-right: 55px;
    }
}
