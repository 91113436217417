////
/// Attention
/// @description Boxes for highlighting important information or warnings.
///
/// @example[html] Default colored attention bar with little content
///   <div class="oui-attention oui-text--center">
///      This is a note.
///   </div>
/// @example[html] Default colored attention bar with long content
///   <div class="oui-attention">
///      This is a longer note adipisicing elit. Recusandae ipsum tenetur est quisquam beatae aliquam facere molestiae re endis aperiam molestias consequuntur voluptatum corporis voluptas laudantium dolor, nam quam laboriosam harum.
///   </div>
/// @example[html] Default colored attention bar with close button
///   <div class="oui-attention oui-text--center">
///     <svg class="oui-icon oui-attention__close">
///       <use xlink:href="#close-16"></use>
///     </svg>
///     This is a note.
///   </div>
/// @example[html] Brand colored attention bar
///   <div class="oui-attention oui-attention--brand oui-text--center">
///     This is a note.
///   </div>
/// @example[html] Warning colored attention bar
///   <div class="oui-attention oui-attention--warning oui-text--center">
///     This is a note.
///   </div>
/// @example[html] Success colored attention bar
///   <div class="oui-attention oui-attention--good-news oui-text--center">
///     This is a note.
///   </div>
/// @example[html] Error colored attention bar
///   <div class="oui-attention oui-attention--bad-news oui-text--center">
///     This is a note.
///   </div>
////

@use "@optimizely/design-tokens/dist/scss/colors" as axiom-colors;

@import "../../oui/oui-utils";
@import "../../overrides/Margin/index.scss";

%#{$namespace}attention,
.#{$namespace}attention,
.oui-attention {
    @extend %kill-last-child-margin;
    position: relative;
    padding: spacer(1);
    border-radius: map-fetch($border, radius, base);
    border: 1px solid map-fetch($border, color, base);

    &--brand {
        border-color: axiom-colors.$alert-brand-border-color;
        background-color: axiom-colors.$alert-brand-background-color;
    }

    &--warning {
        border-color: axiom-colors.$alert-warning-border-color;
        background-color: axiom-colors.$alert-warning-background-color;
    }

    &--good-news {
        border-color: axiom-colors.$alert-good-news-border-color;
        background-color: axiom-colors.$alert-good-news-background-color;
    }

    &--bad-news {
        border-color: axiom-colors.$alert-bad-news-border-color;
        background-color: axiom-colors.$alert-bad-news-background-color;
    }

    &.soft-quin--sides {
        padding-left: spacer(5);
        padding-right: spacer(5);
    }

    &__close {
        position: absolute;
        top: spacer(1);
        right: spacer(1);
    }
}
