@import "../../oui/oui-utils";

.oui-avatar {
    border-radius: 100%;
    height: 80px;
    width: 80px;
    background-position: center center;
    background-size: cover;
    box-shadow: 0 0 0 1.5px rgba(255, 255, 255, 0.75);
    background-image: url('data:image/svg+xml;utf8,<svg width="80px" height="80px" viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>profile-default</title><desc>Created with Sketch.</desc><defs><path d="M40,80 C62.09139,80 80,62.09139 80,40 C80,17.90861 62.09139,0 40,0 C17.90861,0 0,17.90861 0,40 C0,62.09139 17.90861,80 40,80 Z" id="path-1"/></defs><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="profile-default"><rect id="Rectangle-1" fill="%23E1E1E1" x="0" y="0" width="80" height="80"/><g id="AccountSettings--030315"><g id="profile"><g id="Page-1-Copy"><g id="Page-1-Clipped"><g id="Page-1" mask="url(%23mask-2)"><g transform="translate(5.000000, 16.000000)" id="Group"><g><g transform="translate(0.500000, 0.000000)" id="Artboard-22-Copy" fill="%2346456A"><g><g id="Imported-Layers"><path d="M52.574692,18.0018537 C52.574692,27.6002356 44.4936226,35.3857134 34.5222222,35.3857134 C24.5508218,35.3857134 16.4659025,27.6002356 16.4659025,18.0018537 C16.4659025,8.39976439 24.5508218,0.61428655 34.5222222,0.61428655 C44.4936226,0.61428655 52.574692,8.39976439 52.574692,18.0018537" id="Fill-1"/><path d="M0.350447129,87.5367649 C0.350447129,87.5367649 -5.93785298,33 34.7599548,33 C75.4577627,33 68.6058054,87.5367649 68.6058054,87.5367649 C68.6058054,87.5367649 56.196541,91.94262 34.522162,91.94262 C12.847783,91.94262 0.350447129,87.5367649 0.350447129,87.5367649" id="Fill-2"/></g></g></g></g></g></g></g></g></g></g></g></g></svg>');

    &.color-admin--border {
        box-shadow: 0 0 0 1.5px map-fetch($color, ui, admin);
    }
}

.oui-avatar--small {
    height: 27px;
    width: 27px;
}

.oui-avatar--medium {
    height: 50px;
    width: 50px;
}
