.user-group-form-tabs {
    &__container {
        height: 100%;

        > div {
            margin: auto;
        }

        ul {
            border-bottom-color: #c4c4c4;
        }

        li:first-child {
            margin-left: 0 !important;
        }

        li:last-child {
            margin-right: 0;
        }
    }

    &__tab {
        margin-right: 8px;
        background: #fff !important;
        border-radius: 0 !important;
    }

    &__button {
        background: transparent;
        border: none;
    }
}
