// Copyright 2017 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.

@use 'sass:list';
@use 'sass:map';

@forward './variables';
@forward './mixins';
@use './variables';
@use './mixins';
@import "../../oui/oui-utils";

:root {
    @each $size in map.keys(variables.$columns) {
        --axiom-layout-grid-margin-#{$size}: #{map.get(variables.$default-margin, $size)};
        --axiom-layout-grid-gutter-#{$size}: #{map.get(variables.$default-gutter, $size)};
    }
}

.axiom-layout-grid-container {
    @each $size in map.keys(variables.$columns) {
        @include mixins.media-query_($size) {
            $margin: map.get(variables.$default-margin, $size);

            @include mixins.layout-grid($size, $margin, variables.$max-width);
        }
    }
}

.axiom-layout-grid {
    grid-template-rows: min-content;

    @each $size in map.keys(variables.$columns) {
        @include mixins.media-query_($size) {
            $margin: map.get(variables.$default-margin, $size);
            $gutter: map.get(variables.$default-gutter, $size);

            @include mixins.inner($size, $margin, $gutter);
        }
    }
}

.axiom-layout-grid__cell {
    // Select the upper breakpoint
    $upper-breakpoint: list.nth(map.keys(variables.$columns), 1);

    @each $size in map.keys(variables.$columns) {
        @include mixins.media-query_($size) {
            $gutter: map.get(variables.$default-gutter, $size);

            @include mixins.cell($size, variables.$default-column-span, $gutter);

            @for $span from 1 through map.get(variables.$columns, $upper-breakpoint) {
                // Span classes.
                @at-root .axiom-layout-grid__cell--span-#{$span},
                    .axiom-layout-grid__cell--span-#{$span}-#{$size} {
                    @include mixins.cell-span_($size, $span, $gutter);
                }
            }
        }
    }
}
