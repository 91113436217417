@font-face {
    font-family: "Inter";
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
    font-named-instance: "Regular";
    src: url(src/Inter-roman.var.woff2) format("woff2");
}
@font-face {
    font-family: "Inter";
    font-weight: 100 900;
    font-display: swap;
    font-style: italic;
    font-named-instance: "Italic";
    src: url(src/Inter-italic.var.woff2) format("woff2");
}

@font-face {
    font-family: "Hack";
    src: url(src/Hack-regular.woff2) format("woff2");
    font-weight: 400;
    font-style: normal;
  }

@font-face {
    font-family: "Hack";
    src: url(src/Hack-bold.woff2) format("woff2");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Hack";
    src: url(src/Hack-italic.woff2) format("woff2");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Hack";
    src: url(src/Hack-bolditalic.woff2) format("woff2");
    font-weight: 700;
    font-style: italic;
}
