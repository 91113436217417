.list-table {
    height: 100%;
}

.entitlements__table,
.user-manager__table {
    overflow-y: auto; // make the table scrollable if height is more than 500 px
    min-height: 100px;
    thead {
        tr {
            position: sticky; // make the table header sticky
            top: 0; // table head will be placed at the top of the table and sticks to it
            z-index: 1;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 1.5px;
                width: 100%;
                height: 25px;
                background-color: #d6d6d6;
                z-index: -1;
            }
        }

        th {
            background-color: #fff;
            position: relative;
            z-index: 0;
        }

        th,
        td {
            box-sizing: border-box;
        }
    }

    &--more-menu {
        display: flex;
        justify-content: center;
        align-content: center;
        text-align: right;
        position: absolute;
        max-height: 22.5px;
    }
}
