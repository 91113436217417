////
/// Help
/// @example[html]
///   Text with a help icon. <span class="#{$namespace}help-dot"></span>
////

.#{$namespace}help-dot {
    background: map-fetch($color, background, muted);
    color: map-fetch($color, text, white);
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    font-size: 10px;

    &::after {
        content: "?";
    }
}
