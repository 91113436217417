// Prefix any global classes with `admin-center` to avoid potential conflicts
// with imported style sheets
.admin-center {
    &__form-dropdown {
        background-color: #fff;
        button {
            color: var(--light-blue-75);
            border-color: var(--light-blue-75);
        }
    }

    &__dropdown-search-list-item {
        height: 45px;
        position: fixed;
        background-color: #fff;
        width: 100%;
        input {
            margin: 4px;
            width: calc(100% - 8px); // dropdown items have 4px padding on each side, makes it snug
        }
    }

    /* this will make the title show on hover on even disabled buttons */
    button:hover:after {
        position: absolute;
        top: 0;
        left: 75%;
        width: 100%;
        content: attr(title);
        background-color: #ffa;
        color: #000;
        line-height: 1.4em;
        border: 1px solid #000;
    }
}
