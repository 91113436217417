// Limit the width of forms and longer form content.
.reading-column {
    max-width: map-fetch($layout, reading, max-width);
    min-width: map-fetch($layout, reading, min-width);
    margin: spacer(4) auto;
    padding-left: spacer(2);
    padding-right: spacer(2);
}

.reading-column--new {
    max-width: map-fetch($layout, reading, max-width);
    min-width: map-fetch($layout, reading, min-width);
    margin: spacer(4) auto;
    padding-left: spacer(2);
    padding-right: spacer(2);

    h1,
    h2,
    h3 {
        margin-bottom: spacer(0.5);
    }
    * + h1 {
        margin-top: spacer(4);
    }
    * + h2 {
        margin-top: spacer(3);
    }
    * + h3 {
        margin-top: spacer(2);
    }
    * + h4 {
        margin-top: spacer(1);
    }
}
