@import "../../oui/oui-utils";

$nav-border-color: map-fetch($border, color, base);
$nav-item-min-height: spacer(6);

.oui-vertical-tab {
    background: #fff;
    min-width: 285px;
    max-width: 285px;
    overflow: hidden;
    border-right: 1px solid $nav-border-color;
    height: 100%;

    .sidenav {
        &__header {
            flex: none;
            min-width: 0;
            min-height: 0;
            padding: spacer(2) spacer(3) 0;
            background: map-fetch($brand-color, paper);
            position: relative;

            &__title {
                margin-bottom: spacer(0.5);
                line-height: 1.3;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                &:only-child {
                    padding: 17px 0 5px;
                }
            }

            &__item {
                line-height: 1;
                margin-bottom: spacer(1);
            }

            &--full-height {
                display: flex;
                flex-direction: column;
                flex: 1;
            }

            .sidenav__details {
                &__title {
                    h5 {
                        font-size: 13px;
                    }
                }
            }
        }

        &__body {
            flex: 1;
            min-width: 0;
            min-height: 0;
            overflow-y: auto;
            padding: spacer(2);
            background: map-fetch($brand-color, paper);

            .icon-status--active {
                vertical-align: middle;

                &:before {
                    background: map-fetch($color, background, good-news);
                    position: relative;
                    left: -2px;
                }
            }

            &--empty {
                margin-top: spacer(4);
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &--no-top-padding {
                padding-top: 0;
            }
        }

        &__empty-message {
            svg {
                display: block;
                width: 40px;
                height: 40px;
                fill: map-fetch($color, text, muted);
                margin: 0 auto spacer(1);
            }
        }

        &__footer {
            flex: none;
            min-width: 0;
            min-height: 0;
            background: map-fetch($color, background, faint);
            padding: spacer(1) spacer(2);
            border-top: 1px solid #dddddd;
        }

        &__section {
            &__title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 spacer(-2);
                padding: spacer(3) spacer(3) spacer(0.5);
                height: spacer(6);
                box-sizing: border-box;
            }
            &:first-child {
                .sidenav__section__title {
                    border-top: 1px solid $nav-border-color;
                }
            }
        }

        &__change-list {
            margin-left: spacer(1);
            margin-bottom: 0;
            font-size: 12px;

            > li {
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    left: spacer(-1);
                    top: 6px;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                }
            }

            &--draft:before {
                background: map-fetch($color, ui, draft);
            }

            &--live:before {
                background: map-fetch($color, ui, live);
            }
        }

        &__popover {
            z-index: map-fetch($z-index, poptip);
        }
    }

    .nav-list {
        margin-left: spacer(-2);
        margin-right: spacer(-2);
        border-top: 1px solid $nav-border-color;

        &--changes {
            .icon-status--active {
                position: absolute;
                top: 50%;
                margin-top: -10px;
                right: spacer(3.5);
            }
        }

        > li {
            position: relative;
            // border: 1px solid transparent;
            border-bottom: 1px solid $nav-border-color;
            min-height: $nav-item-min-height;
            // margin-top: -1px;
            box-sizing: border-box;

            &.nav-list__link {
                display: flex;
                align-items: center;
                width: 100%;

                .link {
                    padding: spacer(1) spacer(3);
                }

                &:hover {
                    transition: background map-fetch($transition-duration, base);
                    background: var(--highlight);
                    cursor: pointer;
                    color: var(--light-blue-75);
                }

                &--disabled {
                    opacity: map-fetch($opacity, medium);
                }

                &--disabled:after {
                    display: none;
                }

                &--disabled:hover {
                    background: inherit;
                    cursor: default;
                    border: 1px solid transparent;
                    border-bottom: 1px solid $nav-border-color;
                    box-shadow: none;
                }

                &.is-active {
                    background: map-fetch($root-color, highlight);
                    color: var(--light-blue-75);
                }

                &--nested {
                    padding-right: spacer(4);

                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: spacer(2);
                        margin-top: -4px; // Fixes vertical alignment.
                    }
                }

                &--change-dependency:before {
                    display: block;
                    content: "then";
                    position: absolute;
                    z-index: 2;
                    top: spacer(-1);
                    right: spacer(0.5);
                    background: white;
                    color: map-fetch($color, text, muted);
                    padding: 0 spacer(0.5);
                    border-radius: map-fetch($badge, border-radius);
                    font-size: map-fetch($font, size, micro);
                    pointer-events: none;
                }
            }
        }
    }

    .nav-list__link__close {
        position: absolute;
        top: spacer(1);
        right: spacer(1.5);
        cursor: pointer;
        color: #dddddd;

        &:hover {
            .lego-icon {
                color: #555;
            }
        }
    }

    .nav-list-group {
        margin-bottom: spacer(2);
    }

    .nav-link {
        padding: 5px spacer(1);
        margin-left: spacer(-1);
        border-radius: 999px;
        &:hover {
            background-color: var(--highlight);
        }

        &--back:before {
            border: 1px solid currentColor;
            border-top: none;
            border-right: none;
            display: inline-block;
            height: 7px;
            margin-left: 3px;
            margin-right: 4px;
            margin-top: -4px;
            transform: rotate(45deg);
            vertical-align: middle;
            width: 7px;
            content: "";
        }
    }

    .nav-list__link {
        .oui-manager-side-nav__custom-link, /* legacy */
        .oui-vertical-tab__custom-link {
            @extend .link;
            width: 100%;
            height: 100%;
            min-height: calc(#{$nav-item-min-height} - 2px);
            display: flex;
            align-items: center;

            &--active {
                background: map-fetch($root-color, highlight);
                color: var(--light-blue-75);
            }
        }
    }
}
