// Do not edit directly
// Generated on Tue, 02 Aug 2022 12:57:01 GMT

$letter-spacing: (
    xxs: 0.005rem,
    xs: 0rem,
    caption: -0.006rem,
    body: -0.011rem,
    header-5: -0.014rem,
    header-4: -0.017rem,
    header-3: -0.019rem,
    header-2: -0.02rem,
    header-1: -0.021rem,
    subhead: -0.011rem,
);
$font-sizes: (
    xxs: 0.6875rem,
    xs: 0.75rem,
    caption: 0.8125rem,
    body: 0.875rem,
    header-5: 1.125rem,
    header-4: 1.25rem,
    header-3: 1.437rem,
    header-2: 1.625rem,
    header-1: 1.8125rem,
    subhead: 0.875rem,
);
$line-heights: (
    xxs: 1rem,
    xs: 1.25rem,
    caption: 1.5rem,
    body: 1.5rem,
    header-5: 2rem,
    header-4: 2rem,
    header-3: 2.5rem,
    header-2: 2.75rem,
    header-1: 3rem,
    subhead: 1.5rem,
);
$font-weights: (
    extra-light: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semi-bold: 600,
);
$font-families: (
    inter: #{Inter,
    sans-serif},
    hack: #{Hack,
    monospace},
);
$header-1: (
    font-family: #{Inter,
    sans-serif},
    font-weight: 200,
    line-height: 3rem,
    font-size: 1.8125rem,
    letter-spacing: -0.021rem,
);
$header-3: (
    font-family: #{Inter,
    sans-serif},
    font-weight: 300,
    line-height: 2.5rem,
    font-size: 1.437rem,
    letter-spacing: -0.019rem,
);
$header-4: (
    font-family: #{Inter,
    sans-serif},
    font-weight: 400,
    line-height: 2rem,
    font-size: 1.25rem,
    letter-spacing: -0.017rem,
);
$caption: (
    font-family: #{Inter,
    sans-serif},
    font-weight: 400,
    line-height: 1.5rem,
    font-size: 0.8125rem,
    letter-spacing: -0.006rem,
);
$xs: (
    font-family: #{Inter,
    sans-serif},
    font-weight: 400,
    line-height: 1.25rem,
    font-size: 0.75rem,
    letter-spacing: 0rem,
);
$xxs: (
    font-family: #{Inter,
    sans-serif},
    font-weight: 400,
    line-height: 1rem,
    font-size: 0.6875rem,
    letter-spacing: 0.005rem,
);
$header-5: (
    font-family: #{Inter,
    sans-serif},
    font-weight: 500,
    line-height: 2rem,
    font-size: 1.125rem,
    letter-spacing: -0.014rem,
);
$header-2: (
    font-family: #{Inter,
    sans-serif},
    font-weight: 200,
    line-height: 2.75rem,
    font-size: 1.625rem,
    letter-spacing: -0.02rem,
);
$body: (
    font-family: #{Inter,
    sans-serif},
    font-weight: 400,
    line-height: 1.5rem,
    font-size: 0.875rem,
    letter-spacing: -0.011rem,
);
$subhead: (
    font-family: #{Inter,
    sans-serif},
    font-weight: 600,
    line-height: 1.5rem,
    font-size: 0.875rem,
    letter-spacing: -0.011rem,
);
$code: (
    font-family: #{Hack,
    monospace},
    font-weight: 400,
    line-height: 1.5rem,
    font-size: 0.875rem,
    letter-spacing: 0rem,
);
