@use "sass:map";
@use "@optimizely/design-tokens/dist/scss/typography-map" as axiom-typography-map;
@import "../../oui/oui-utils";

.axiom-typography {
    &--body {
        font-family: map.get(axiom-typography-map.$body, "font-family");
        font-weight: map.get(axiom-typography-map.$body, "font-weight");
        line-height: map.get(axiom-typography-map.$body, "line-height");
        font-size: map.get(axiom-typography-map.$body, "font-size");
        letter-spacing: map.get(axiom-typography-map.$body, "letter-spacing");
    }

    &--code {
        font-family: map.get(axiom-typography-map.$code, "font-family");
        font-weight: map.get(axiom-typography-map.$code, "font-weight");
        line-height: map.get(axiom-typography-map.$code, "line-height");
        font-size: map.get(axiom-typography-map.$code, "font-size");
        letter-spacing: map.get(axiom-typography-map.$code, "letter-spacing");
    }

    &--caption {
        font-family: map.get(axiom-typography-map.$caption, "font-family");
        font-weight: map.get(axiom-typography-map.$caption, "font-weight");
        line-height: map.get(axiom-typography-map.$caption, "line-height");
        font-size: map.get(axiom-typography-map.$caption, "font-size");
        letter-spacing: map.get(axiom-typography-map.$caption, "letter-spacing");
    }

    &--header1 {
        font-family: map.get(axiom-typography-map.$header-1, "font-family");
        font-weight: map.get(axiom-typography-map.$header-1, "font-weight");
        line-height: map.get(axiom-typography-map.$header-1, "line-height");
        font-size: map.get(axiom-typography-map.$header-1, "font-size");
        letter-spacing: map.get(axiom-typography-map.$header-1, "letter-spacing");
    }

    &--header2 {
        font-family: map.get(axiom-typography-map.$header-2, "font-family");
        font-weight: map.get(axiom-typography-map.$header-2, "font-weight");
        line-height: map.get(axiom-typography-map.$header-2, "line-height");
        font-size: map.get(axiom-typography-map.$header-2, "font-size");
        letter-spacing: map.get(axiom-typography-map.$header-2, "letter-spacing");
    }

    &--header3 {
        font-family: map.get(axiom-typography-map.$header-3, "font-family");
        font-weight: map.get(axiom-typography-map.$header-3, "font-weight");
        line-height: map.get(axiom-typography-map.$header-3, "line-height");
        font-size: map.get(axiom-typography-map.$header-3, "font-size");
        letter-spacing: map.get(axiom-typography-map.$header-3, "letter-spacing");
    }

    &--header4 {
        font-family: map.get(axiom-typography-map.$header-4, "font-family");
        font-weight: map.get(axiom-typography-map.$header-4, "font-weight");
        line-height: map.get(axiom-typography-map.$header-4, "line-height");
        font-size: map.get(axiom-typography-map.$header-4, "font-size");
        letter-spacing: map.get(axiom-typography-map.$header-4, "letter-spacing");
    }

    &--header5 {
        font-family: map.get(axiom-typography-map.$header-5, "font-family");
        font-weight: map.get(axiom-typography-map.$header-5, "font-weight");
        line-height: map.get(axiom-typography-map.$header-5, "line-height");
        font-size: map.get(axiom-typography-map.$header-5, "font-size");
        letter-spacing: map.get(axiom-typography-map.$header-5, "letter-spacing");
    }

    &--subhead {
        font-family: map.get(axiom-typography-map.$subhead, "font-family");
        font-weight: map.get(axiom-typography-map.$subhead, "font-weight");
        line-height: map.get(axiom-typography-map.$subhead, "line-height");
        font-size: map.get(axiom-typography-map.$subhead, "font-size");
        letter-spacing: map.get(axiom-typography-map.$subhead, "letter-spacing");
    }

    &--xs {
        font-family: map.get(axiom-typography-map.$xs, "font-family");
        font-weight: map.get(axiom-typography-map.$xs, "font-weight");
        line-height: map.get(axiom-typography-map.$xs, "line-height");
        font-size: map.get(axiom-typography-map.$xs, "font-size");
        letter-spacing: map.get(axiom-typography-map.$xs, "letter-spacing");
    }

    &--xxs {
        font-family: map.get(axiom-typography-map.$xxs, "font-family");
        font-weight: map.get(axiom-typography-map.$xxs, "font-weight");
        line-height: map.get(axiom-typography-map.$xxs, "line-height");
        font-size: map.get(axiom-typography-map.$xxs, "font-size");
        letter-spacing: map.get(axiom-typography-map.$xxs, "letter-spacing");
    }

    &--numeric {
        font-feature-settings: "tnum", "zero" 0;
    }

    &--invisible {
        opacity: 0;
    }

    @at-root[class^="axiom-typography--"] {
        &[class*="duration-"] {
            transition-property: opacity;
            transition-timing-function: ease-out;
        }
        &.duration-150 {
            transition-duration: 150ms;
        }
        &.duration-300 {
            transition-duration: 300ms;
        }
    }
}

