.permission {
    &__project-header {
        display: flex;
        justify-content: space-between;
    }

    &__dropdown {
        background-color: #fff;
        button {
            color: var(--light-blue-75);
            border-color: var(--light-blue-75);
        }
        margin-bottom: 15px;
    }
}
