.user-list {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    &__bar {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin: 24px 0;
    }

    &__search {
        text-align: left;
        min-width: 266px;
        padding-left: 18px;
        display: flex;
        div:first-child {
            width: 100%;
        }
    }

    &__leftgroup {
        display: flex;
    }

    &__invite-user {
        text-align: right;
    }

    &__table {
        position: relative;
    }

    &__activation-status--active {
        color: var(--axiom-green-base);
    }
}
