////
/// Pagination
/// @description This is a variation of the navigation object.
/// @example[html]
///   <ol class="#{$namespace}nav #{$namespace}pagination">
///     <li class="#{$namespace}pagination__first"><a href="#">First</a></li>
///     <li class="#{$namespace}pagination__prev"><a href="#">Previous</a></li>
///     <li><a href="/page/1">1</a></li>
///     <li><a href="/page/2">2</a></li>
///     <li class="#{$namespace}pagination__current"><a href="/page/3">3</a></li>
///     <li><a href="/page/4">4</a></li>
///     <li><a href="/page/5">5</a></li>
///     <li class="#{$namespace}pagination__next"><a href="/page/next">Next</a></li>
///     <li class="#{$namespace}pagination__last"><a href="/page/last">Last</a></li>
///   </ol>
////

@import "../../oui/oui-utils";

.#{$namespace}pagination {
    justify-content: center;

    > li {
        padding: spacer(0.5);
    }

    &__first a::before {
        content: "\00AB""\00A0";
    }

    &__last a::after {
        content: "\00A0""\00BB";
    }

    &__current > a {
        color: map-fetch($color, text, base);
    }
}
