@import "../../components/Sidebar/SidebarForm.global.css";

:root {
    --layout-padding: 24px 32px;
}

.layout-container {
    padding: var(--layout-padding);
    height: 100%;
    box-sizing: border-box;
}

.layout-container .list-page {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
}

.button-as-link {
    padding: 0;
    border: 0;
    background: none;
    text-align: left;
}

.layout-header {
    display: flex;
    align-items: center;
    margin: 0 16px 16px;
}
