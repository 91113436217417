@import "../../oui/oui-utils";

$block-link-padding: 26px;
$block-link-side-padding: 23px; // Introduced side padding separately since the new icons are wider
$block-secondarylink-padding: 16px;
$icon-dimensions--large: 30px; // Fixed width size of medium icons
$icon-dimensions--small: 20px; // Fixed width size of small icons
$animation-speed: 150ms;
$avatar-size: 29px;
$root-shadow: inset -1px 0 3px 0 rgba(0, 0, 0, 0.1);

.root-nav {
    display: flex;
    flex-direction: column;
    position: relative;
    height: fit-content;
    min-height: 100%;
    z-index: 2001;
    padding: spacer(2.5) 0;
    color: var(--dark-blue-100);
    background: var(--dark-paper);
    width: 76px;
    transition: width $animation-speed ease-in;
    &:after {
        transition: width $animation-speed ease-in;
        content: "";
        position: absolute;
        top: 0;
        background-color: transparent;
        bottom: 0;
        width: 100px;
        left: -24px;
        z-index: 0;
        box-shadow: $root-shadow;
        pointer-events: none;
    }

    .click-area {
        cursor: pointer !important;
    }
}

.root-nav__link {
    display: flex;
    align-items: center;
    color: var(--dark-blue-100);
    padding-top: $block-link-padding / 3;
    padding-bottom: $block-link-padding / 3;
    @media (min-height: 1600px) {
        padding-top: $block-link-padding / 2;
        padding-bottom: $block-link-padding / 2;
    }
    width: 76px;
    transition: width $animation-speed ease-in;
    overflow: hidden;

    .axiom-icon {
        color: inherit;
    }
}

.root-nav__link__text {
    margin-left: spacer(1);
    display: none;
}
.root-nav__link--secondary .root-nav__link__text {
    margin-left: spacer(1.5);
}

// Link--reverse text is white on white
// TODO(dave) remove link--reverse from these <a>s
.root-nav__link .link--reverse {
    color: var(--dark-blue-100) !important;
    &:hover,
    &:active {
        color: var(--light-blue-75) !important;
    }
}
.root-nav__link .link--reverse.admin--color {
    color: var(--purple) !important;
}

.root-nav__project,
.root-nav__link--primary {
    padding-left: $block-link-side-padding;
    padding-right: $block-link-side-padding;
}

.root-nav__project {
    white-space: nowrap;
    transition: opacity $animation-speed ease-in;
    opacity: 1;
    margin-top: 0 !important; // TODO(drau) HTML FIX
    padding-left: spacer(2); // TODO(drau) need sidenav component!
}

.root-nav__project,
.root-nav__link__text {
    opacity: 1;
    transition: opacity $animation-speed ease-in;
    transition-delay: $animation-speed / 1.2;
}

.root-nav__logo {
    height: 30px;
    position: relative;
    flex: none;
}

// Overwrite some styles when the navigation is expanded.
.root-nav--open {
    width: 226px;
    transition: width $animation-speed ease-in;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        background-color: transparent;
        bottom: 0;
        width: 226px;
        left: 0;
        z-index: 0;
        box-shadow: $root-shadow;
    }

    .root-nav__logo--full {
        transition: opacity $animation-speed ease-in;
        opacity: 1;
    }
    .root-nav__logo--mark {
        opacity: 0;
    }

    .root-nav__link__text {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .root-nav__link {
        transition: width $animation-speed ease-in;
        width: 226px;
        overflow: visible;
    }
}

.root-nav__link-external-icon {
    visibility: hidden;
}

.root-nav__link--primary {
    &:hover {
        background: var(--light-blue-25);
        color: var(--light-blue-75);
        .root-nav__link-external-icon {
            visibility: visible;
        }
    }

    &.is-active,
    &.is-active:hover {
        background: var(--light-blue-25);
    }

    &.has-external-icon {
        padding-right: 18px;
    }
}

.root-nav__link--secondary,
.root-nav__link--tertiary {
    &,
    &:visited {
        color: var(--dark-blue-100);
    }
    &:hover {
        color: var(--light-blue-75);
        .root-nav__link-external-icon {
            visibility: visible;
        }
    }
    &.has-external-icon {
        padding-right: 18px;
    }
}
.root-nav__link--secondary,
.root-nav__link--tertiary,
.root-nav__link--plain {
    font-size: map-fetch($font, size, micro);
}

.root-nav__link--tertiary,
.root-nav__link--plain {
    padding-left: ($block-link-side-padding) - (($avatar-size - $icon-dimensions--large) / 2);
    padding-right: ($block-link-side-padding) - (($avatar-size - $icon-dimensions--large) / 2);
}

.root-nav__link--secondary {
    min-height: 2.3em;
    padding-top: $block-secondarylink-padding / 4;
    padding-bottom: $block-secondarylink-padding / 4;
    @media (min-height: 1600px) {
        padding-top: $block-secondarylink-padding / 2;
        padding-bottom: $block-secondarylink-padding / 2;
    }
    padding-left: ($block-link-side-padding) + ($icon-dimensions--small / 4);
    padding-right: ($block-link-side-padding) + ($icon-dimensions--small / 4);
}

.root-nav__logo--full,
.root-nav__logo--mark {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: auto 100%;
    height: 100%;
    transition: opacity $animation-speed ease-in;
}

.root-nav__logo--full {
    opacity: 0;
}

.root-nav__logo--mark {
    opacity: 1;
}

.root-nav-fader {
    opacity: 0;
    transition: opacity $animation-speed ease-in;

    * {
        cursor: default !important; // scss-lint:disable ImportantRule
    }
}

.oui-dropdown > li.oui-dropdown__item.oui-dropdown__block-link,
.root-nav__user .lego-block-list__link {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.root-nav__user .lego-block-list li > div.soft-half--sides {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.root-nav__user {
    align-items: flex-start;
}
