// These tokens have been deprecated. Use @optimizely/design-tokens instead.
$brand-blue-x-light: hsla(226, 100%, 97%, 1);
$brand-blue-light: hsla(227, 100%, 95%, 1);
$brand-blue-base: hsla(227, 100%, 85%, 1);
$brand-blue-dark: hsla(227, 100%, 50%, 1);
$brand-purple-x-light: hsla(240, 9%, 96%, 1);
$brand-purple-light: hsla(246, 11%, 82%, 1);
$brand-purple-base: hsla(240, 11%, 65%, 1);
$brand-purple-dark: hsla(241, 77%, 12%, 1);
$smoke-x-light: hsla(0, 0%, 98%, 1);
$smoke-light: hsla(0, 0%, 96%, 1);
$smoke-base: hsla(0, 0%, 93%, 1);
$smoke-dark: hsla(0, 0%, 84%, 1);
$grey-x-light: hsla(0, 0%, 96%, 1);
$grey-light: hsla(0, 0%, 85%, 1);
$grey-base: hsla(0, 0%, 62%, 1);
$grey-dark: hsla(0, 0%, 44%, 1);
$purple-x-light: hsla(263, 48%, 95%, 1);
$purple-light: hsla(263, 46%, 79%, 1);
$purple-base: hsla(263, 52%, 47%, 1);
$purple-dark: hsla(263, 51%, 33%, 1);
$magenta-x-light: hsla(310, 56%, 94%, 1);
$magenta-light: hsla(311, 56%, 75%, 1);
$magenta-base: hsla(311, 95%, 37%, 1);
$magenta-dark: hsla(311, 94%, 26%, 1);
$pink-x-light: hsla(340, 86%, 95%, 1);
$pink-light: hsla(341, 87%, 85%, 1);
$pink-base: hsla(340, 82%, 52%, 1);
$pink-dark: hsla(341, 55%, 48%, 1);
$red-x-light: hsla(0, 91%, 96%, 1);
$red-light: hsla(0, 89%, 83%, 1);
$red-base: hsla(0, 89%, 57%, 1);
$red-dark: hsla(0, 67%, 45%, 1);
$orange-x-light: hsla(36, 100%, 95%, 1);
$orange-light: hsla(36, 100%, 80%, 1);
$orange-base: hsla(36, 100%, 50%, 1);
$orange-dark: hsla(36, 100%, 32%, 1);
$yellow-x-light: hsla(46, 100%, 95%, 1);
$yellow-light: hsla(45, 100%, 80%, 1);
$yellow-base: hsla(45, 100%, 51%, 1);
$yellow-dark: hsla(45, 96%, 43%, 1);
$green-x-light: hsla(120, 38%, 95%, 1);
$green-light: hsla(123, 38%, 80%, 1);
$green-base: hsla(122, 39%, 49%, 1);
$green-dark: hsla(122, 39%, 34%, 1);
$aqua-x-light: hsla(189, 72%, 94%, 1);
$aqua-light: hsla(187, 73%, 77%, 1);
$aqua-base: hsla(187, 100%, 42%, 1);
$aqua-dark: hsla(187, 100%, 27%, 1);
$blue-x-light: hsla(209, 91%, 95%, 1);
$blue-light: hsla(207, 89%, 82%, 1);
$blue-base: hsla(207, 90%, 54%, 1);
$blue-dark: hsla(207, 77%, 38%, 1);
$white: hsla(0, 0%, 100%, 1);
$black: hsla(0, 0%, 0%, 1);
$icon-small: 12px;
$icon-large: 16px;
$nubbin-size: 24px;
