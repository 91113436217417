.role-form {
    #role-form-content {
        min-height: calc(100% - 71px);
    }
    &__description {
        resize: none;
    }
    // TODO: Move product instance selection to a component and remove this css
    &__dropdown {
        background-color: #fff;
        button {
            color: var(--light-blue-75);
            border-color: var(--light-blue-75);
        }
    }
    &__instance-selections {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
