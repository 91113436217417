// Do not edit directly
// Generated on Tue, 02 Aug 2022 12:57:01 GMT

$code-letter-spacing: 0rem;
$font-families-hack: Hack, monospace;
$font-families-inter: Inter, sans-serif;
$font-weights-semi-bold: 600;
$font-weights-medium: 500;
$font-weights-regular: 400;
$font-weights-light: 300;
$font-weights-extra-light: 200;
$line-heights-subhead: 1.5rem;
$line-heights-header-1: 3rem;
$line-heights-header-2: 2.75rem;
$line-heights-header-3: 2.5rem;
$line-heights-header-4: 2rem;
$line-heights-header-5: 2rem;
$line-heights-body: 1.5rem;
$line-heights-caption: 1.5rem;
$line-heights-xs: 1.25rem;
$line-heights-xxs: 1rem;
$font-sizes-subhead: 0.875rem;
$font-sizes-header-1: 1.8125rem;
$font-sizes-header-2: 1.625rem;
$font-sizes-header-3: 1.437rem;
$font-sizes-header-4: 1.25rem;
$font-sizes-header-5: 1.125rem;
$font-sizes-body: 0.875rem;
$font-sizes-caption: 0.8125rem;
$font-sizes-xs: 0.75rem;
$font-sizes-xxs: 0.6875rem;
$letter-spacing-subhead: -0.011rem;
$letter-spacing-header-1: -0.021rem;
$letter-spacing-header-2: -0.02rem;
$letter-spacing-header-3: -0.019rem;
$letter-spacing-header-4: -0.017rem;
$letter-spacing-header-5: -0.014rem;
$letter-spacing-body: -0.011rem;
$letter-spacing-caption: -0.006rem;
$letter-spacing-xs: 0rem;
$letter-spacing-xxs: 0.005rem;
$code-font-size: $font-sizes-body;
$code-line-height: $line-heights-body;
$code-font-weight: 400;
$code-font-family: $font-families-hack;
$subhead-letter-spacing: $letter-spacing-subhead;
$subhead-font-size: $font-sizes-subhead;
$subhead-line-height: $line-heights-subhead;
$subhead-font-weight: 600;
$subhead-font-family: $font-families-inter;
$body-letter-spacing: $letter-spacing-body;
$body-font-size: $font-sizes-body;
$body-line-height: $line-heights-body;
$body-font-weight: 400;
$body-font-family: $font-families-inter;
$header-2-letter-spacing: $letter-spacing-header-2;
$header-2-font-size: $font-sizes-header-2;
$header-2-line-height: $line-heights-header-2;
$header-2-font-weight: 200;
$header-2-font-family: $font-families-inter;
$header-5-letter-spacing: $letter-spacing-header-5;
$header-5-font-size: $font-sizes-header-5;
$header-5-line-height: $line-heights-header-5;
$header-5-font-weight: 500;
$header-5-font-family: $font-families-inter;
$xxs-letter-spacing: $letter-spacing-xxs;
$xxs-font-size: $font-sizes-xxs;
$xxs-line-height: $line-heights-xxs;
$xxs-font-weight: 400;
$xxs-font-family: $font-families-inter;
$xs-letter-spacing: $letter-spacing-xs;
$xs-font-size: $font-sizes-xs;
$xs-line-height: $line-heights-xs;
$xs-font-weight: 400;
$xs-font-family: $font-families-inter;
$caption-letter-spacing: $letter-spacing-caption;
$caption-font-size: $font-sizes-caption;
$caption-line-height: $line-heights-caption;
$caption-font-weight: 400;
$caption-font-family: $font-families-inter;
$header-4-letter-spacing: $letter-spacing-header-4;
$header-4-font-size: $font-sizes-header-4;
$header-4-line-height: $line-heights-header-4;
$header-4-font-weight: 400;
$header-4-font-family: $font-families-inter;
$header-3-letter-spacing: $letter-spacing-header-3;
$header-3-font-size: $font-sizes-header-3;
$header-3-line-height: $line-heights-header-3;
$header-3-font-weight: 300;
$header-3-font-family: $font-families-inter;
$header-1-letter-spacing: $letter-spacing-header-1;
$header-1-font-size: $font-sizes-header-1;
$header-1-line-height: $line-heights-header-1;
$header-1-font-weight: 200;
$header-1-font-family: $font-families-inter;
