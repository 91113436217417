////
/// Buttons
/// @example[html] Button colors
///   <button class="oui-button">Button</button>
///   <button class="oui-button oui-button--highlight">Button</button>
///   <button class="oui-button oui-button--danger">Button</button>
///   <button class="oui-button oui-button--danger-outline">Button</button>
///   <button class="oui-button oui-button--outline">Button</button>
///   <button class="oui-button oui-button--underline">Button <span class="oui-arrow-inline--down"></span></button>
///   <button class="oui-button oui-button--plain">Button</button>
/// @example[html] Tiny buttons
///   <button class="oui-button oui-button--tiny">Button</button>
///   <button class="oui-button oui-button--small">Button</button>
/// @example[html] Full width buttons
///   <button class="oui-button oui-button--full">Button</button>
/// @example[html] Disabled buttons
///   <button class="oui-button oui-button--disabled">Disabled with Class</button>
///   <button class="oui-button" disabled>Disabled with Attribute</button>
////
@use "@optimizely/design-tokens/dist/scss/colors" as axiom-colors;

@import "../../oui/oui-utils";

%#{$namespace}button,
.#{$namespace}button,
%oui-button,
.oui-button {
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    font-family: inherit;
    cursor: pointer;
    margin: 0;
    line-height: map-fetch($button, size, base, line-height);
    border-width: 1px;
    border-style: solid;
    font-size: map-fetch($button, size, base, font-size);
    font-weight: map-fetch($button, size, base, font-weight);
    user-select: none;
    border-radius: map-fetch($button, size, base, border-radius);
    height: map-fetch($button, size, base, height);
    padding: map-fetch($button, size, base, padding);
    transition: color map-fetch($transition-duration, base), background-color map-fetch($transition-duration, base),
        box-shadow map-fetch($transition-duration, base), border-color map-fetch($transition-duration, base);

    // Unique styles for the base, button start here.
    background-color: transparent;
    border-color: $grey-base;
    color: map-fetch($button, type, base, color);

    &:visited {
        color: map-fetch($button, type, base, color);
    }

    &:hover {
        color: $brand-blue-dark;
        border: 1px solid $brand-blue-base;
    }

    &:focus {
        outline: none;
        color: $brand-blue-dark;
        border: 1px solid $brand-blue-dark;
    }

    &.is-active,
    &:active {
        background: $base-white;
        border-color: map-fetch($button, type, base, active, border);
        color: $brand-blue-dark;
        box-shadow: map-fetch($button, type, base, active, box-shadow);
    }

    &--highlight {
        background-color: map-fetch($button, type, highlight, background);
        border-color: map-fetch($button, type, highlight, border);
        color: map-fetch($color, text, white);

        &:hover {
            background: map-fetch($button, type, highlight, hover, background);
            border-color: map-fetch($button, type, highlight, hover, border);
        }

        &:focus {
            border: 1px solid map-fetch($button, type, highlight, focus, border);
        }

        &:active {
            background: map-fetch($button, type, highlight, active, background);
            border-color: map-fetch($button, type, highlight, active, border);
            box-shadow: map-fetch($button, type, highlight, active, box-shadow);
        }

        &:visited,
        &:hover,
        &:active,
        &:focus {
            color: map-fetch($color, text, white);
        }
    }

    &--outline {
        background-color: map-fetch($button, type, outline, background);
        border-color: map-fetch($button, type, outline, border);
        color: map-fetch($button, type, outline, color);

        &.oui-button--white-background {
            background-color: $base-white;
        }

        &:visited {
            color: map-fetch($button, type, outline, color);
        }

        &:focus {
            color: map-fetch($button, type, outline, color);
            border: 1px solid map-fetch($button, type, outline, hover, border);
        }

        &:hover {
            background-color: map-fetch($button, type, outline, background);
            border-color: map-fetch($button, type, outline, hover, border);
            color: map-fetch($button, type, outline, hover, color);
            box-shadow: none;
        }

        &:active {
            background-color: map-fetch($button, type, outline, background);
            border-color: map-fetch($button, type, outline, active, border);
            color: map-fetch($button, type, outline, active, color);
            box-shadow: none;
        }
    }

    &--danger {
        background-color: map-fetch($button, type, danger, background);
        border-color: map-fetch($button, type, danger, border);
        color: map-fetch($color, text, white);

        &:hover {
            background: map-fetch($button, type, danger, hover, background);
            border-color: map-fetch($button, type, danger, hover, border);
        }

        &:active {
            background: map-fetch($button, type, danger, active, background);
            border-color: map-fetch($button, type, danger, active, border);
        }

        &:focus {
            border: 1px solid shade(map-fetch($button, type, danger, background), 30%);
        }

        &:visited,
        &:hover,
        &:active,
        &:focus {
            color: map-fetch($color, text, white);
        }
    }

    &--danger-outline {
        color: map-fetch($button, type, danger-outline, color);
        border-color: map-fetch($button, type, danger-outline, border);
        background-color: transparent;
        box-shadow: none;

        &:focus {
            color: map-fetch($button, type, danger-outline, color);
            border: 1px solid shade(map-fetch($button, type, danger, background), 30%);
        }

        &:hover {
            color: map-fetch($button, type, danger-outline, hover, color);
            border-color: map-fetch($button, type, danger-outline, hover, border);
            background-color: transparent;
            box-shadow: none;
        }

        &:active {
            border-color: map-fetch($button, type, danger-outline, active, border);
            background-color: transparent;
            color: map-fetch($button, type, danger-outline, active, color);
            box-shadow: none;
        }
    }

    &--plain {
        color: map-fetch($button, type, plain, color);
        border-color: transparent;
        background-color: transparent;
        box-shadow: none;

        &:hover,
        &:focus {
            color: map-fetch($button, type, plain, hover, color);
            border: 1px solid $brand-blue-base;
        }

        &:active,
        &:active {
            color: axiom-colors.$button-plain-pressed-label-color;
            border-color: axiom-colors.$button-plain-pressed-border-color;
            background-color: axiom-colors.$button-plain-pressed-background-color;
        }
        &.is-active:hover,
        &.is-active:focus {
            color: map-fetch($button, type, base, active, color);
        }
    }

    &--underline {
        border-radius: 0 !important;
        border-color: transparent;
        background-color: transparent;
        box-shadow: none;
        border-bottom: 2px solid map-fetch($button, type, underline, border);
        color: map-fetch($button, type, base, color);
        padding-left: spacer(0.5);
        padding-right: spacer(0.5);

        &:visited,
        &:hover,
        &:active,
        &:focus {
            border-color: transparent;
            background-color: transparent;
            box-shadow: none;
        }

        &:hover {
            border-bottom-width: 2px;
            border-bottom-color: map-fetch($button, type, underline, hover, border);
            color: map-fetch($button, type, underline, color);
            & .oui-arrow-inline--down {
                color: map-fetch($button, type, underline, hover, arrow-color);
            }
        }

        &:focus {
            border-bottom-width: 2px;
            border-bottom-color: map-fetch($button, type, underline, focus, border);
            & .oui-arrow-inline--down {
                color: map-fetch($button, type, underline, focus, border);
            }
        }

        &:active {
            border-bottom-width: 2px;
            border-bottom-color: map-fetch($button, type, underline, active, border);
            color: map-fetch($button, type, underline, active, color);
            & .oui-arrow-inline--down {
                color: map-fetch($button, type, underline, active, arrow-color);
            }
        }
    }

    &--toggle {
        background-color: transparent;
        border-color: transparent;

        &:hover,
        &:focus {
            color: axiom-colors.$button-toggle-hover-icon-color;
            border-color: axiom-colors.$button-toggle-hover-border-color;
        }

        &:active,
        &.is-active {
            color: axiom-colors.$button-toggle-pressed-icon-color;
            background: axiom-colors.$button-toggle-pressed-background-color;
            border-color: axiom-colors.$button-toggle-pressed-border-color;
            box-shadow: inset 0px 0px 3px 1px axiom-colors.$button-toggle-pressed-shadow-color;
        }

        &:active:hover,
        &:active:focus,
        &.is-active:hover,
        &.is-active:focus {
            color: axiom-colors.$button-toggle-pressed-hover-icon-color;
            background: axiom-colors.$button-toggle-pressed-hover-background-color;
            border-color: axiom-colors.$button-toggle-pressed-hover-border-color;
            box-shadow: inset 0px 0px 3px 1px axiom-colors.$button-toggle-pressed-hover-shadow-color;
        }
    }

    // Button Sizes

    &--tiny {
        font-size: map-fetch($button, size, tiny, font-size);
        font-weight: map-fetch($button, size, tiny, font-weight);
        border-radius: map-fetch($button, size, tiny, border-radius);
        line-height: map-fetch($button, size, tiny, line-height);
        height: map-fetch($button, size, tiny, height);
        padding: map-fetch($button, size, tiny, padding);
    }

    &--small {
        font-size: map-fetch($button, size, small, font-size);
        font-weight: map-fetch($button, size, small, font-weight);
        border-radius: map-fetch($button, size, small, border-radius);
        line-height: map-fetch($button, size, small, line-height);
        height: map-fetch($button, size, small, height);
        padding: map-fetch($button, size, small, padding);
    }

    &--large {
        font-size: map-fetch($button, size, large, font-size);
        font-weight: map-fetch($button, size, large, font-weight);
        border-radius: map-fetch($button, size, large, border-radius);
        line-height: map-fetch($button, size, large, line-height);
        height: map-fetch($button, size, large, height);
        padding: map-fetch($button, size, large, padding);
    }

    &--narrow {
        padding-left: spacer(1);
        padding-right: spacer(1);
    }

    &--tight {
        padding-left: spacer(0.5);
        padding-right: spacer(0.5);
    }

    &--full {
        width: 100%;
        text-align: center;
    }

    // With Icon
    // Some extra help is needed to get the icon and text to line up.

    &--icon {
        line-height: 1.4;

        .#{$namespace}icon {
            vertical-align: sub;
        }
    }

    &--unstyled {
        // scss-lint:disable ImportantRule
        background: inherit !important;
        border: none !important;
        border-radius: inherit !important;
        box-shadow: inherit !important;
        color: inherit !important;
        font-size: inherit !important;
        font-weight: inherit !important;
        height: inherit !important;
        line-height: 1 !important;
        margin: 0 !important;
        padding: 0 !important;
        text-shadow: inherit !important;
        transition: none;

        &:hover,
        &:focus {
            color: map-fetch($color, link, hover) !important;
        }

        &:focus {
            border: 1px solid var(--light-blue-25) !important;
            box-shadow: 0px 0px 3px $brand-blue-dark !important;
        }
    }

    // Disabled State
    // Used either an attribute or a class, depending on the use case.

    &[disabled],
    &--disabled,
    &--disabled:visited,
    &--disabled:hover,
    &--disabled:active,
    &--disabled:focus {
        cursor: default;
        box-shadow: none;
        background-color: map-fetch($button, type, disabled, background);
        border-color: map-fetch($button, type, disabled, border);
        color: map-fetch($button, type, disabled, color);
        box-shadow: none;

        &.#{$namespace}button--plain,
        &.#{$namespace}button--toggle,
        &.oui-button--plain,
        &.oui-button--toggle {
            background-color: transparent;
            border-color: transparent;
        }

        &.#{$namespace}button--outline-reverse,
        &.oui-button--outline-reverse {
            background-color: transparent;
        }

        &.oui-button--underline {
            background-color: transparent;
            border-color: transparent;
            border-bottom: 2px solid map-fetch($button, type, disabled, border);
        }
    }

    &--loading {
        .axiom-spinner {
            margin-right: spacer(0.5);
        }
    }

    // Don't override the fill colors of ButtonIcon
    &:not(.oui-button-icon) {
        svg {
            // Have icons inherit the default color from the button styles
            color: inherit;
        }
    }
}
