.support-access {
    &__search {
        text-align: left;
        min-width: 266px;
        padding-left: 18px;
        display: flex;
        div:first-child {
            width: 100%;
        }
    }
}
