/* 
 * These styles work together to ensure the 
 * Sidebar does not create a horizontal scrollbar when
 * off screen.  
 */
.um-sidebar--open {
    overflow-x: hidden;
}

.um-sidebar.visibility--hidden {
    transform: translateX(100%);
    position: fixed !important;
}
