////
/// Lists
/// @description We provide classes to add bullets/numbers since we most
/// commonly use `ol/ul` without the default styling.
/// @example[html]
///   <ul class="#{$namespace}list #{$namespace}list--bullet">
///     <li>item one</li>
///     <li>item two</li>
///     <li>item three</li>
///   </ul>
/// @example[html]
///    <ul class="#{$namespace}list #{$namespace}list--numbered">
///      <li>item one</li>
///      <li>item two</li>
///      <li>item three</li>
///    </ul>
////

%#{$namespace}list,
.#{$namespace}list {
    margin-bottom: spacer(1);

    &--bullet {
        margin-left: spacer(2);
        list-style-type: disc;
    }

    &--numbered {
        margin-left: spacer(2.5);
        list-style-type: decimal;
    }

    &--lower-alpha {
        margin-left: spacer(2.5);
        list-style-type: lower-alpha;
    }

    &--spaced {
        > li {
            margin-bottom: spacer(0.5);
        }
    }
}

// Nested List Spacing
// Vertical spacing will be removed from nested lists.

li {
    > ul,
    > ol {
        margin-bottom: 0;
    }
}
