@import "../../oui/oui-utils";
@import "../../overrides/Border/index.scss";

.oui-docked-footer {
    @extend .border--top;
    background-color: white;
    padding-bottom: spacer(2);
    box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.12);
    transition: all 0.2s ease-in-out;
    margin-bottom: spacer(-2);
    padding-top: spacer(2);
}

.oui-docked-footer.oui-docked-footer--is-docked {
    position: sticky;
    bottom: 0;
    box-shadow: 0 spacer(-1) spacer(1) spacer(-1) rgba(0, 0, 0, 0.12);
}

.oui-docked-footer .oui-button-row--right {
    margin-right: spacer(1);
}

.oui-docked-footer .oui-button-row--left {
    margin-left: spacer(1);
}
