.add-group-access {
    display: flex;

    &__group-dropdown {
        // overwrites Axiom fullWidth prop for dropdown, making space for the necessary icon buttons + padding
        width: calc(100% - 100px);
    }

    &__icons {
        margin-top: 28px; // height of dropdown label + 2 to align
        align-self: center;
        justify-self: center;
    }
}
