////
/// OUI Variables
/// @description Variables for all elements and objects in OUI should be listed
/// here.
////
@use "@optimizely/design-tokens/dist/scss/colors" as axiom-colors;

// scss-lint:disable SpaceAfterVariableName

/// Namespace
/// @description Provide a name space for most classes.

@use "@optimizely/design-tokens/dist/scss/typography" as axiom-typography;

$namespace: '' !default;

/// Spacing Unit
/// @description Defines spacing for paddings and margins using `spacer`
/// function.

$spacer-unit: 8px;

/// Core Colors
/// @description Default color for objects' borders etc.

/// Do not use these values directly in your Sass files. Use the more
/// specifically named variables below that use these root values.

$base-black: axiom-colors.$neutral-colors-black;
$base-white: axiom-colors.$neutral-colors-white;

/// TODO: @dave.rau Combine brand, root and new tokens into 1 map

$brand-color: (
    /* brand */
    light-blue-100: axiom-colors.$brand-primary-color,
    light-blue-75: axiom-colors.$brand-primary-color,
    light-blue-50: axiom-colors.$brand-blue-base,
    light-blue-25: axiom-colors.$brand-blue-light,
    light-blue-10: axiom-colors.$brand-blue-extra-light,
    light-blue-6: axiom-colors.$brand-blue-extra-light,
    dark-blue-75: axiom-colors.$brand-purple-dark,
    /* indicators */
    gold: axiom-colors.$yellow-base,
    red: axiom-colors.$red-base,
    green: axiom-colors.$green-base,
    indigo: axiom-colors.$purple-base,
    cyan: axiom-colors.$aqua-blue-base,
    blue: axiom-colors.$blue-base,
    orange: axiom-colors.$orange-base,
    /* greys */
    dark-blue-100: axiom-colors.$brand-purple-dark,
    dark-blue-50: axiom-colors.$brand-purple-base,
    dark-blue-25: axiom-colors.$brand-purple-base,
    grey-50: axiom-colors.$smoke-base,
    grey-25: axiom-colors.$smoke-light,
    paper: axiom-colors.$brand-background-primary-color,
    dark-paper: axiom-colors.$brand-background-secondary-color,
    white-40b: shade($base-white, 40%)
);

$root-color: (
    grey-85: tint($base-black, 15%),
    grey-78: tint($base-black, 22%),
    grey-50: tint($base-black, 50%),
    grey-40: tint($base-black, 60%),
    grey-35: tint($base-black, 65%),
    grey-25: tint($base-black, 75%),
    grey-12: tint($base-black, 88%),
    grey-06: tint($base-black, 94%),
    grey-03: tint($base-black, 97%),
    brand: map-fetch($brand-color, light-blue-75),
    brand-light: map-fetch($brand-color, light-blue-25),
    brand-dark: map-fetch($brand-color, dark-blue-75),
    warning: map-fetch($brand-color, gold),
    bad-news: map-fetch($brand-color, red),
    good-news: map-fetch($brand-color, green),
    draft: map-fetch($brand-color, orange),
    live: map-fetch($brand-color, green),
    admin: map-fetch($brand-color, indigo),
    highlight: map-fetch($brand-color, light-blue-6),
);

/// Base Colors
/// @description These are colors can be used on UI elements like borders.

$color: (
    ui: (
        white: $base-white,
        faint: $base-white,
        //map-fetch($root-color, grey-03),
        light: map-fetch($root-color, grey-06),
        base: map-fetch($root-color, grey-12),
        medium: map-fetch($root-color, grey-40),
        charcoal: map-fetch($root-color, grey-78),
        dark: map-fetch($root-color, grey-85),
        brand: map-fetch($root-color, brand),
        brand-light: map-fetch($root-color, brand-light),
        brand-dark: map-fetch($root-color, brand-dark),
        warning: map-fetch($root-color, warning),
        bad-news: map-fetch($root-color, bad-news),
        good-news: map-fetch($root-color, good-news),
        draft: map-fetch($root-color, draft),
        live: map-fetch($root-color, live),
        admin: map-fetch($root-color, admin),
        highlight: map-fetch($root-color, highlight),
    ),
    link: (
        base: map-fetch($brand-color, light-blue-75),
        hover: map-fetch($brand-color, light-blue-100),
        brand-light: map-fetch($root-color, brand-light),
        bad-news: map-fetch($root-color, bad-news),
        white: $base-white,
    ),
    background: (
        base: $base-white,
        white: $base-white,
        faint: $base-white,
        light: map-fetch($root-color, grey-06),
        muted: map-fetch($root-color, grey-35),
        medium: map-fetch($root-color, grey-50),
        charcoal: map-fetch($root-color, grey-78),
        black: $base-black,
        brand: map-fetch($root-color, brand),
        brand-dark: map-fetch($root-color, brand-dark),
        brand-light: tint(map-fetch($root-color, brand), 80%),
        warning: tint(map-fetch($root-color, warning), 80%),
        bad-news: tint(map-fetch($root-color, bad-news), 80%),
        good-news: tint(map-fetch($root-color, good-news), 80%),
    ),
    text: (
        base: axiom-colors.$brand-text-primary-color,
        white: $base-white,
        faint: map-fetch($root-color, grey-12),
        light: map-fetch($root-color, grey-25),
        muted: axiom-colors.$grey-dark,
        medium: map-fetch($root-color, grey-50),
        charcoal: map-fetch($root-color, grey-78),
        dark: map-fetch($root-color, grey-85),
        brand: map-fetch($root-color, brand),
        brand-dark: map-fetch($root-color, brand-dark),
        warning: map-fetch($root-color, warning),
        bad-news: map-fetch($root-color, bad-news),
        good-news: shade(map-fetch($root-color, good-news), 20%),
    ),
);

/// Font Sizes
/// @description These defaults can be overridden by custom variables.

$font: (
    size: (
        base: axiom-typography.$body-font-size, // ( = 16px )
        alpha: axiom-typography.$header-1-font-size, // h1
        beta: axiom-typography.$header-2-font-size, // h2
        gamma: axiom-typography.$header-3-font-size, // h3
        delta: axiom-typography.$header-4-font-size, // h4
        epsilon: axiom-typography.$header-5-font-size, // h5
        zeta: axiom-typography.$caption-font-size, // h6
        milli: axiom-typography.$xs-font-size, // ( = 12px)
        micro: axiom-typography.$xxs-font-size, // ( = 11px )
        kilo: 40px, // Extra large for marketing
        mega: 50px,
        giga: 65px,
    ),
    line-height: (
        base: 1.6,
        tight: 1.4,
        loose: 1.8,
    ),
    family: (
        base: axiom-typography.$font-families-inter,
        number: axiom-typography.$font-families-inter,
        monospace: axiom-typography.$font-families-hack
    ),
    letter-spacing: (
        loose: 1px,
        tight: -1px,
        extra-tight: -2px,
    ),
);

/// Buttons

$button: (
    size: (
        base: (
            height: 34px,
            line-height: 32px,
            font-size: 13px,
            padding: 0 15px,
            font-weight: 400,
            border-radius: 4px,
        ),
        large: (
            height: 42px,
            line-height: 41px,
            font-size: 15px,
            padding: 0 20px,
            font-weight: 400,
            border-radius: 4px,
        ),
        small: (
            height: 27px,
            line-height: 26px,
            font-size: 11px,
            padding: 0 7px,
            font-weight: 400,
            border-radius: 4px,
        ),
        tiny: (
            height: 19px,
            line-height: 18px,
            font-size: 11px,
            padding: 0 8px,
            font-weight: 400,
            border-radius: 4px,
        ),
    ),
    type: (
        base: (
            background: map-fetch($root-color, grey-03),
            border: map-fetch($root-color, grey-25),
            color: map-fetch($color, text, base),
            hover: (
                box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.16),
                background: $base-white,
            ),
            active: (
                background: map-fetch($brand-color, dark-blue-75),
                border: map-fetch($brand-color, dark-blue-75),
                color: $base-white,
                box-shadow: inset 1px 0 3px 0 rgba(0, 0, 0, 0.5),
                text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5),
            ),
        ),
        highlight: (
            background: map-fetch($brand-color, light-blue-75),
            border: tint(map-fetch($brand-color, light-blue-75), 10%),
            text-shadow: 0 0 1px rgba(0, 0, 0, 0.2),
            hover: (
                background: map-fetch($brand-color, light-blue-100),
                border: tint(map-fetch($brand-color, light-blue-100), 10%),
            ),
            active: (
                background: shade(map-fetch($brand-color, light-blue-100), 20%),
                border: shade(map-fetch($brand-color, light-blue-100), 30%),
                box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5),
            ),
            focus: (
                border: map-fetch($brand-color, light-blue-100),
            ),
        ),
        outline: (
            background: transparent,
            border: map-fetch($brand-color, light-blue-75),
            color: map-fetch($brand-color, light-blue-75),
            hover: (
                border: map-fetch($brand-color, light-blue-100),
                color: map-fetch($brand-color, light-blue-100),
            ),
            active: (
                border: map-fetch($brand-color, dark-blue-100),
                color: map-fetch($brand-color, dark-blue-100),
            ),
        ),
        underline: (
            border: map-fetch($root-color, grey-35),
            color: map-fetch($color, text, base),
            hover: (
                border: map-fetch($root-color, brand),
                arrow-color: map-fetch($root-color, brand),
            ),
            focus: (
                border: map-fetch($brand-color, light-blue-100),
                arrow-color: map-fetch($brand-color, light-blue-100),
            ),
            active: (
                border: map-fetch($color, background, brand),
                color: map-fetch($color, background, brand-dark),
                arrow-color: map-fetch($color, background, brand-dark),
            ),
        ),
        danger: (
            background: map-fetch($brand-color, red),
            border: map-fetch($brand-color, red),
            text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3),
            hover: (
                background: shade(map-fetch($brand-color, red), 20%),
                border: shade(map-fetch($brand-color, red), 20%),
            ),
            active: (
                background: shade(map-fetch($brand-color, red), 30%),
                border: shade(map-fetch($brand-color, red), 30%),
            ),
        ),
        danger-outline: (
            color: map-fetch($brand-color, red),
            border: map-fetch($brand-color, red),
            hover: (
                color: shade(map-fetch($brand-color, red), 20%),
                border: shade(map-fetch($brand-color, red), 20%),
            ),
            active: (
                border: shade(map-fetch($brand-color, red), 30%),
                color: shade(map-fetch($brand-color, red), 30%),
            ),
        ),
        plain: (
            color: map-fetch($brand-color, dark-blue-75),
            hover: (
                color: map-fetch($brand-color, light-blue-75),
            ),
            active: (
                color: map-fetch($brand-color, dark-blue-100),
            ),
        ),
        disabled: (
            background: map-fetch($brand-color, grey-25),
            border: shade(map-fetch($brand-color, grey-50), 10%),
            color: map-fetch($color, text, muted),
        ),
    ),
);

/// Tokens

$token: (
    background-color: (
        primary: axiom-colors.$blue-base,
        secondary: axiom-colors.$grey-dark,
        tertiary: map-fetch($brand-color, grey-50),
        error: map-fetch($brand-color, red),
    ),
    selected: map-fetch($brand-color, light-blue-100),
    font-color: (
        light: $base-white,
        dark: map-fetch($brand-color, dark-blue-100),
    ),
    token__number: (
        min-width: 10px,
    ),
    token__close: (
        width: 12px,
        height: 12px,
    ),
    move-drag-handle: map-fetch($brand-color, white-40b),
    border-radius: 5px,
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.2),
    padding: 4px,
);

/// Switch

$switch: (
    background-color: (
        on: axiom-colors.$brand-blue-dark,
        off: axiom-colors.$grey-dark
    )
);

/// Rangeslider

$rangeslider: (
    background-color: axiom-colors.$brand-blue-dark
);

/// Badge

$badge: (
    background-color: axiom-colors.$grey-dark,
    border-radius: 16px, // DT-TODO: Replace with token once available
    font-size: axiom-typography.$xxs-font-size,
    font-weight: 600, // DT-TODO: Replace with token once available
    height: 16px, // DT-TODO: Replace with token once available
    letter-spacing: axiom-typography.$xxs-letter-spacing,
    line-height: axiom-typography.$xxs-line-height,
    min-width: 32px,
    size: axiom-typography.$xxs-font-size,
);

/// Icons

$icon: (
    size: (
        base: (
            width: 16px,
            height: 16px,
        ),
        small: (
            width: 12px,
            height: 12px,
        ),
        medium: (
            width: 24px,
            height: 24px,
        ),
        large: (
            width: 32px,
            height: 32px,
        ),
    ),
    type: (
        target: (
            padding: 6px,
        ),
    ),
);

/// Forms

$form: (
    input: (
        padding: 7px,
    ),
);

/// Dropdowns

$dropdown: (
    max-height: 320px,
);

/// Borders

$border: (
    color: (
        base: axiom-colors.$border-divider-color,
    ),
    radius: (
        base: 5px,
        big: 8px,
        full: 50%,
    ),
);

$input-border: (
    color: (
        base: axiom-colors.$border-user-input-color,
    ),
);

/// Shadows

$shadow: (
    base: 4px rgba(0, 0, 0, 0.2),
    light: 3px rgba(0, 0, 0, 0.1),
    big: 8px rgba(0, 0, 0, 0.3),
);

/// Opacity

$opacity: (
    medium: 0.5,
    heavy: 0.9,
);

/// Dialog widths

$dialog: (
    width: (
        base: 700px,
        narrow: 500px,
        wide: 900px,
    ),
    footer: (
        color: #f7f7f7,
    ),
);

/// Layout

$layout: (
    reading: (
        max-width: 700px,
        min-width: 700px,
    ),
);

/// Transitions

$transition-duration: (
    fast: 0.1s,
    base: 0.3s,
    slow: 0.5s,
);

/// Accordion

$accordion-link-height: 26px;

/// Popover

$popover: (
    max-width: 350px,
);

/// Poptip

$poptip: (
    max-width: 250px,
);

/// Poptip

$edittext: (
    spacer: 8px,
);

/// Progress Bar

$progress-bar: (
    height: 20px,
    bar: (
        min-width: 20px,
    ),
);

/// Progress Dots

$progress-dots: (
    width: 7px,
    height: 7px,
    margin: 3px,
    width-small: 4px,
    height-small: 4px,
    margin-small: 1px,
);

/// Spinner

$spinner: (
    size: (
        base: 40px,
        small: 26px,
        tiny: 16px,
    ),
    border: (
        width: (
            base: 4px,
            small: 3px,
            tiny: 2px,
        ),
    ),
    speed: 800ms,
);

/// Z-index Values

$z-index: (
    dialog: 3000,
    feedback: 3100,
    input-right-container: 200,
    poptip: 100,
    sidebar: 99,
    toolbar: 1,
    toolbar-shadow: -1,
);
