.um-sidebar {
    // overrides Axiom's class .background-white that is added to the Sidebar
    background-color: #f3f6ff !important;
    transform: translateX(0);
    overflow-x: hidden;
    overflow-y: auto !important;
    position: fixed !important;
    // make up for sticky global nav
    height: calc(100vh - var(--global-bar-height)) !important;
    top: var(--global-bar-height);

    .um-sidebar-body {
        // overrides Axiom style that prevents the bottom padding
        // from getting include as part of the scroll area
        min-height: auto;
    }

    &__heading {
        padding: 0 32px;
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        // prevent long names/email addresses from creating horizontal scrollbar in sidebar
        word-break: break-word;
    }

    // centers any child dialog to window instead of component
    &--no-transform {
        transform: none !important;
    }

    // Show resize handle
    > div:last-child {
        div {
            height: 54px !important;
            width: 13px !important;
            background-color: #c1c1cd;
            border-radius: 5px;
            top: calc(50% - 27px) !important;
        }
    }
}

.um-sidebar-close {
    flex: 0 0 auto;
    align-self: flex-end;
    padding: 8px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 50%;
    background-color: transparent;
    margin: 16px 16px 0 0;
    svg {
        font-size: 30px;
        vertical-align: middle;
    }
    &:hover {
        border: none;
        background-color: var(--light-blue-25);
        color: var(--light-blue-75);
    }
}
