////
/// Switch
/// @description switch a state to on/off.
///
/// @example[html] Default spinner
/// <input type="checkbox" id="switch1" class="oui-switch" />
/// <label for="switch1" data-on-label="On" data-off-label="Off"></label>

@import "../../oui/oui-utils";

/* don't hide input from screen-readers and keyboard access */
.oui-switch {
    position: absolute;
    opacity: 0;
    z-index: 3;
}
.oui-switch:checked ~ span a {
    right: 0%;
}

.oui-switch + label {
    font-size: 1em;
    line-height: 1;
    width: 4.3rem;
    height: 2.3rem;
    background-color: map-fetch($switch, background-color, off);
    background-image: none;
    border-radius: 2rem;
    padding: 0.1666666667rem;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    position: relative;
    -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2) inset;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2) inset;
    font-family: inherit;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    -webkit-user-select: none;
    border: 2px solid white;
}

/* Label */
.oui-switch + label:before {
    color: #fff;
    content: attr(data-off-label);
    display: block;
    font-family: inherit;
    font-family: FontAwesome, inherit;
    font-weight: 900;
    font-size: 0.85rem;
    line-height: 1.74rem;
    position: absolute;
    right: 0.2166666667rem;
    margin: 0.2166666667rem;
    top: 0;
    text-align: center;
    min-width: 1.6666666667rem;
    overflow: hidden;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

/* Slider */
.oui-switch + label:after {
    content: "";
    position: absolute;
    left: 0.1666666667rem;
    background-color: #f7f7f7;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 2rem;
    height: 1.6666666667rem;
    width: 1.6666666667rem;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

.oui-switch:checked + label {
    background-color: map-fetch($switch, background-color, on);
}

.oui-switch:checked + label:before {
    color: #fff;
    content: attr(data-on-label);
    right: auto;
    left: 0.2166666667rem;
}

.oui-switch:checked + label:after {
    left: 2.1666666667rem;
    background-color: #f7f7f7;
    -webkit-box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.3);
}

.oui-switch:focus + label,
.oui-switch:active + label {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2) inset, 0 0 0 2px var(--light-blue-25) !important;
}

.oui-switch--disabled + label {
    opacity: 0.4;
    pointer-events: none;
}
.oui-switch--disabled:checked + label {
    opacity: 0.2;
}
