.roles-page {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    &__table {
        position: relative;
    }

    &__bar {
        display: flex;
        flex-direction: row;
        margin: 24px 0;
    }

    &__search {
        text-align: left;
        min-width: 266px;
        padding-left: 18px;
        padding-right: 18px;
    }

    &__left-grouping {
        display: flex; /* Use flex layout */
        align-items: center; /* Vertically center items */
    }

    &__add-role {
        margin-left: auto;
    }
}
