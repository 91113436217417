.nav-links {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 5px;
}

.nav-links a {
    padding: 8px 12px;
    font-size: var(--axiom-font-sizes-subhead);
    font-weight: var(--axiom-font-weights-regular);
}

.nav-links a,
.nav-links a:visited {
    color: var(--dark-blue-75);
}

.nav-links a:hover {
    color: var(--light-blue-75);
}

.nav-links a.active {
    border-bottom: 3px solid var(--light-blue-75);
}
