.explicit-role-type-form-fields {
    /* Axiom applies the classname passed in to the `input` but they wrap it with other stuff, bad. This is a workaround. */
    /* ADDITIONALLY, the `labelWeight` prop on the radio doesn't support `bold`????????????? */
    label {
        display: flex;
        align-items: center;
        input[type="radio"] {
            margin-top: 0 !important;
        }
        & > div > div:last-child {
            font-weight: bold !important;
        }
    }
}
