.icon-button {
    padding: 0;
    width: 32px;
    height: 32px;
}

.activation-status-icon {
    padding: 0;
    height: 100%;
}

.user-form-field-label {
    font-weight: 530;
    margin-top: 16px;

    &__top-line {
        font-weight: 530;
        margin-top: 0px;
    }
}
