/** Any styles that need to be made to the global axiom classes should be placed here **/
.nav-list {
    overflow-x: hidden;
}

.oui-text-input:read-only,
.oui-textarea:read-only {
    background-color: var(--grey-50);
}

// Helper classes
.truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.danger {
    color: var(--red);
}

.dropdown-inherit-width {
    .oui-dropdown-children {
        width: inherit !important;
    }
}

.weight--bold {
    font-weight: 700 !important;
}
