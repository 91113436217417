@import "../../oui/oui-utils";

.oui-pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;

    // Prevent jumping when focusing on buttons
    .oui-button--unstyled {
        border: 1px solid transparent !important;
    }
}
