@use "sass:map";
@use "../../LayoutGrid/variables" as layoutGridVariables;
@use "../../LayoutGrid/mixins" as layoutGridMixins;
@import "../../../oui/oui-utils";

$firstColumnSizeCols: (
    xlarge: 2,
    large: 2,
    medium: 2,
    small: 2,
);

$firstColumnSizePx: (
    xlarge: 285px,
    large: 285px,
    medium: 285px,
    small: 285px,
);


.axiom-three-column-layout {
    width: 100%;

    .axiom-layout-grid {
        @each $size in map.keys(layoutGridVariables.$columns) {
            @include layoutGridMixins.media-query_($size) {
                grid-template-columns: repeat( map.get($firstColumnSizeCols, $size), map.get($firstColumnSizePx, $size) / map.get($firstColumnSizeCols, $size))
                                        repeat(map.get(layoutGridVariables.$columns, $size) - map.get($firstColumnSizeCols, $size), minmax(0, 1fr));
            }
        }
    }
}
