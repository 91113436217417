.navigation-bar {
    display: flex;
    height: fit-content;
    min-height: 100%;

    &__main {
        height: 100%;
        padding-top: 32px;
    }

    &__menu-item {
        &--collapsed {
            padding-left: 24px !important; // default is 16, adding some more padding so when it's collapsed it looks better
        }
    }
}
