////
/// Input
/// @example[html]
///   <label>
///     <span class="oui-label">Name</span>
///     <input class="oui-text-input" placeholder="Jane Doe" />
///   </label>
/// @example[html]
///   <label>
///     <span class="oui-label oui-label--disabled">Name</span>
///     <input class="oui-text-input" disabled placeholder="Jane Doe" />
///   </label>
/// @example[html]
///   <label class="#{$namespace}form-bad-news">
///     <span class="oui-label oui-label--required">
///       Experiment Hypothesis
///     </span>
///     <input class="oui-text-input" placeholder="Jane Doe" />
///     <div class="#{$namespace}form-note #{$namespace}form-note--bad-news">This field is required.</div>
///   </label>
////

@import "../../oui/oui-utils";
@import "../../components/InputIcon/index.scss";
@import "../../oui/partials/elements/form";
@import "../../overrides/Margin/index.scss";

.#{$namespace}text-input,
.oui-text-input {
    @include text-input;
}

.#{$namespace}text-input[type="number"],
.oui-text-input[type="number"] {
    text-align: right;
}

.oui-text-input[type="time"] {
    max-height: map-fetch($button, size, base, height);
}

.#{$namespace}text-input--small,
.oui-text-input--small {
    @include text-input--small;
}

.#{$namespace}text-input--disabled,
.oui-text-input--disabled {
    @include text-input--small;
}

.#{$namespace}text-input--read-only,
.oui-text-input--read-only {
    @include text-input--read-only;
}

.oui-text-input-with-icon--left {
    .oui-text-input {
        padding-left: spacer(2) + $icon-large;
    }
}

.oui-text-input-with-icon--right {
    .oui-text-input {
        padding-right: spacer(2) + $icon-large;
    }
}

.oui-text-input__clear-button {
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    right: spacer(1);
}

.oui-text-input__right-container {
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    right: spacer(1);
    z-index: map-fetch($z-index, input-right-container);
}
