.checkbox-list {
    margin-bottom: 16px;

    &__heading {
        padding-bottom: 4px !important;
        label {
            font-weight: var(--axiom-font-weights-semi-bold);
        }
    }

    &__item {
        border-bottom: 1px solid var(--axiom-border-divider-color);
        padding: 4px 0 4px;
    }
}
