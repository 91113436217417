.invitations {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    &__bar {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 24px;
    }

    &__search {
        text-align: left;
        min-width: 266px;
        padding-left: 18px;
    }

    &__add-invitation {
        text-align: right;
    }

    &__table {
        position: relative;
        margin-top: 24px;
    }

    &__status {
        &--accepted {
            color: var(--axiom-green-base);
        }

        &--expired {
            color: var(--axiom-red-base);
        }

        &--revoked {
            color: var(--axiom-red-base);
        }
    }
}
