////
/// Badge
/// @description Useful for counts like the number of live or draft changes.
/// @example[html] Default
///   <span class="oui-badge">Default</span>
/// @example[html] Draft
///   <span class="oui-badge oui-badge--draft">Draft</span>
/// @example[html] Live
///   <span class="oui-badge oui-badge--live">Live</span>
/// @example[html] Primary
///   <span class="oui-badge oui-badge--primary">Primary</span>
/// @example[html] Plain
///   <span class="oui-badge oui-badge--plain">Plain</span>
////
@use "@optimizely/design-tokens/dist/scss/colors" as axiom-colors;
@import "../../oui/oui-utils";

@mixin badge {
    height: map-fetch($badge, height);
    line-height: map-fetch($badge, line-height);
    background: axiom-colors.$badge-default-background-color;
    color: axiom-colors.$badge-default-label-color;
    min-width: map-fetch($badge, min-width);
    text-align: center;
    margin-right: spacer(0.5);
    border-radius: map-fetch($badge, border-radius);
    font-size: map-fetch($badge, font-size);
    padding: 0 spacer(1) !important; // TODO(dave) HTML FIX: remove .soft-half--sides from .badge__draft
    font-weight: map-fetch($badge, font-weight);
    letter-spacing: map-fetch($badge, letter-spacing);
    text-transform: uppercase;
}

.#{$namespace}badge {
    display: flex;
    align-items: center;
    white-space: nowrap;

    > li {
        @include badge;

        &:last-child {
            margin-right: 0;
        }
    }
}

.oui-badge {
    display: inline-block;
    @include badge;
}

.#{$namespace}badge > li.badge__draft,
.oui-badge--draft {
    background: axiom-colors.$badge-draft-background-color;
    color: axiom-colors.$badge-draft-label-color;
}

.#{$namespace}badge > li.badge__live,
.oui-badge--live {
    background: axiom-colors.$badge-live-background-color;
    color: axiom-colors.$badge-live-label-color;
}

.#{$namespace}badge > li.badge__primary,
.oui-badge--primary {
    background: axiom-colors.$badge-primary-background-color;
    color: axiom-colors.$badge-primary-label-color;
}

.#{$namespace}badge > li.badge__plain,
.oui-badge--plain {
    background: transparent;
    color: axiom-colors.$badge-plain-label-color;
}

.#{$namespace}badge > li.badge__bad-news,
.oui-badge--bad-news {
    background: axiom-colors.$badge-bad-background-color;
    color: axiom-colors.$badge-bad-label-color;
}

.#{$namespace}badge > li.badge__new,
.oui-badge--new {
    background: axiom-colors.$badge-new-background-color;
    color: axiom-colors.$badge-new-label-color;
}

.#{$namespace}badge > li.badge__beta,
.oui-badge--beta {
    background: axiom-colors.$badge-beta-background-color;
    color: axiom-colors.$badge-beta-label-color;
}

.oui-badge--purple {
    background: $purple-base;
}