@use "@optimizely/design-tokens/dist/scss/colors" as axiom-colors;
@import "../../oui/oui-utils";

.axiom-checkbox {
    &__input {
        cursor: pointer;
        height: 16px;
        vertical-align: middle;
        width: 16px;
    }

    &__label {
        cursor: pointer;
        padding-left: 8px;
        vertical-align: middle;
    }

    &__description {
        padding-left: 24px;
    }

    &--disabled {
        .axiom-checkbox__input {
            cursor: default;
        }

        .axiom-checkbox__label {
            color: axiom-colors.$checkbox-checked-disabled-label-color;
            cursor: default;
        }

        .axiom-checkbox__description {
            color: axiom-colors.$checkbox-checked-disabled-description-color;
        }
    }
}
