@import "../../oui/oui-utils";

.oui-dropdown-group__activator-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    text-align: left;
    line-height: map-fetch($button, size, base, line-height);
    font-size: map-fetch($button, size, base, font-size);
    font-weight: map-fetch($button, size, base, font-weight);
}
