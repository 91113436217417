.usergroup-list-table {
    position: relative;
}

.usergroup-list-bar {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 24px 0;
}

.add-usergroup {
    text-align: right;
}

.usergroup-search {
    text-align: left;
    min-width: 266px;
    padding-left: 18px;
    padding-right: 18px;
}

.left-grouping {
    display: flex; /* Use flex layout */
    align-items: center; /* Vertically center items */
}

.user-group-type-badge {
    white-space: nowrap;
}
