.axiom-icon {
    &--small {
        font-size: 16px;
    }

    &--medium {
        font-size: 24px;
    }

    &--large {
        font-size: 32px;
    }
}

.axiom-icon-outline path {
    stroke-width: 70px;
}
