////
/// SearchPicker
/// @description A clearable search box.
/// @example[html]
///   <div class="#{$namespace}search" clearevent="clearSearch">
///     <input type="text"
///            class="#{$namespace}text-input #{$namespace}text-input--search width-200"
///            placeholder="Filter by Name"
///            v-on="keyup: setStringFilter($event)"
///            v-model="filters.string">
///   </div>
////

@import "../../oui/oui-utils";

.#{$namespace}search {
    position: relative;

    .#{$namespace}icon {
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        right: spacer(1);
        cursor: pointer;
        display: none;
    }
}

// Using background-image here to avoid hacky CSS.

.#{$namespace}text-input--search,
.oui-text-input--search {
    padding-left: spacer(4);
    background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" xml:space="preserve"><g id="Layer_1"><path d="M6.21,12.417C2.786,12.417,0,9.632,0,6.209C0,2.785,2.786,0,6.21,0c3.422,0,6.206,2.785,6.206,6.209 C12.416,9.632,9.632,12.417,6.21,12.417z M6.21,1C3.337,1,1,3.337,1,6.209s2.337,5.208,5.21,5.208		c2.87,0,5.206-2.336,5.206-5.208S9.08,1,6.21,1z"/><path d="M15.5,16c-0.128,0-0.256-0.049-0.354-0.146l-5.25-5.25c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0l5.25,5.25 	c0.195,0.195,0.195,0.512,0,0.707C15.756,15.951,15.628,16,15.5,16z"/></g></svg>'); // #search16
    background-repeat: no-repeat;
    background-position: left 8px center;
    background-size: 16px 16px;
}

.#{$namespace}search--active .#{$namespace}icon {
    display: block;
}
