////
/// Links
/// @description Links will use the `map-fetch($color, link, base)`. You can
/// also apply a `.link` class to an element to give it the same styles.
/// @example[html]
///   Text can have <span class="link">link color</span> when not a HTML anchor.
/// @example[html]
///   Links can be <a href="#" class="link--disabled">disabled</a> if need be.
////

a,
%link,
.link {
    color: map-fetch($color, link, base);
    transition: color map-fetch($transition-duration, base);
    cursor: pointer;

    &:visited,
    &:active {
        color: map-fetch($color, link, base);
    }

    &:hover {
        color: map-fetch($color, link, hover);
    }
}

%link--dark,
.link--dark {
    color: map-fetch($color, text, base);
    cursor: pointer;

    &:visited,
    &:active {
        color: map-fetch($color, text, base);
    }

    &:hover {
        color: map-fetch($color, link, base);
    }
}

// Use sparingly, such as an icon inside a link that needs a hover state
// (see multipage experience editor tabs for one example)
%link--muted,
.link--muted {
    color: map-fetch($color, text, muted);
    cursor: pointer;

    &:visited,
    &:active {
        color: map-fetch($color, text, muted);
    }

    &:hover {
        color: map-fetch($color, link, base);
    }
}

%link--bad-news,
.link--bad-news {
    color: map-fetch($color, text, base);
    cursor: pointer;

    &:visited,
    &:hover,
    &:active {
        color: map-fetch($color, link, bad-news);
    }
}

%link--reverse,
.link--reverse {
    color: map-fetch($color, link, white) !important;
    cursor: pointer;

    &:hover {
        color: lighten(map-fetch($color, link, brand-light), 15%) !important;
    }
}

%link--disabled,
.link--disabled {
    color: map-fetch($color, text, muted);

    &:visited,
    &:hover,
    &:active {
        color: map-fetch($color, text, muted);
        cursor: default;
    }
}
