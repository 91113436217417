////
/// Poptip
/// @description Information bubbles on hover.
/// @example[html]
///   <div style="position: relative; min-height: 2rem">
///     <div class="oui-pop--tip" style="opacity: 1;">
///       This is a pop-tip. Use only limited text.
///     </div>
///   </div>
/// @example[html]
///   <div style="position: relative; min-height: 2rem">
///     <div class="oui-pop--tip oui-pop--tip--arrow-top-left" style="opacity: 1;">
///       This is a pop-tip. Use only limited text.
///     </div>
///   </div>
/// @example[html]
///   <div style="position: relative; min-height: 2rem">
///     <div class="oui-pop--tip oui-pop--tip--arrow-top-center" style="opacity: 1;">
///       This is a pop-tip. Use only limited text.
///     </div>
///   </div>
/// @example[html]
///   <div style="position: relative; min-height: 2rem">
///     <div class="oui-pop--tip oui-pop--tip--arrow-top-right" style="opacity: 1;">
///       This is a pop-tip. Use only limited text.
///     </div>
///   </div>
/// @example[html]
///   <div style="position: relative; min-height: 2rem">
///     <div class="oui-pop--tip oui-pop--tip--arrow-right-top" style="opacity: 1;">
///       This is a pop-tip. Use only limited text.
///     </div>
///   </div>
/// @example[html]
///   <div style="position: relative; min-height: 2rem">
///     <div class="oui-pop--tip oui-pop--tip--arrow-right-center" style="opacity: 1;">
///       This is a pop-tip. Use only limited text.
///     </div>
///   </div>
/// @example[html]
///   <div style="position: relative; min-height: 2rem">
///     <div class="oui-pop--tip oui-pop--tip--arrow-right-bottom" style="opacity: 1;">
///       This is a pop-tip. Use only limited text.
///     </div>
///   </div>
/// @example[html]
///   <div style="position: relative; min-height: 2rem">
///     <div class="oui-pop--tip oui-pop--tip--arrow-bottom-right" style="opacity: 1;">
///       This is a pop-tip. Use only limited text.
///     </div>
///   </div>
/// @example[html]
///   <div style="position: relative; min-height: 2rem">
///     <div class="oui-pop--tip oui-pop--tip--arrow-bottom-center" style="opacity: 1;">
///       This is a pop-tip. Use only limited text.
///     </div>
///   </div>
/// @example[html]
///   <div style="position: relative; min-height: 2rem">
///     <div class="oui-pop--tip oui-pop--tip--arrow-bottom-left" style="opacity: 1;">
///       This is a pop-tip. Use only limited text.
///     </div>
///   </div>
/// @example[html]
///   <div style="position: relative; min-height: 2rem">
///     <div class="oui-pop--tip oui-pop--tip--arrow-left-bottom" style="opacity: 1;">
///       This is a pop-tip. Use only limited text.
///     </div>
///   </div>
/// @example[html]
///   <div style="position: relative; min-height: 2rem">
///     <div class="oui-pop--tip oui-pop--tip--arrow-left-center" style="opacity: 1;">
///       This is a pop-tip. Use only limited text.
///     </div>
///   </div>
/// @example[html]
///   <div style="position: relative; min-height: 2rem">
///     <div class="oui-pop--tip oui-pop--tip--arrow-left-top" style="opacity: 1;">
///       This is a pop-tip. Use only limited text.
///     </div>
///   </div>
////

@import "../../oui/oui-utils";
@import "../../oui/partials/sass/placeholders";

.#{$namespace}pop--tip,
.oui-pop--tip,
.tippy-popper {
    border-radius: var(--border-radius);
}

.#{$namespace}pop--tip,
.oui-pop--tip {
    background: map-fetch($color, ui, dark);
    color: map-fetch($color, text, white);
    font-size: map-fetch($font, size, micro);
    padding: spacer(0.5) spacer(1);
    max-width: map-fetch($poptip, max-width);
    display: inline-block;
    z-index: map-fetch($z-index, poptip);
    font-weight: 500;
    position: absolute;
    opacity: 0;

    // Arrow options.

    &--arrow-top-left {
        @include arrow(top, left, map-fetch($color, ui, dark));
    }

    &--arrow-top-center {
        @include arrow(top, center, map-fetch($color, ui, dark));
    }

    &--arrow-top-right {
        @include arrow(top, right, map-fetch($color, ui, dark));
    }

    &--arrow-right-top {
        @include arrow(right, top, map-fetch($color, ui, dark));
    }

    &--arrow-right-center {
        @include arrow(right, center, map-fetch($color, ui, dark));
    }

    &--arrow-right-bottom {
        @include arrow(right, bottom, map-fetch($color, ui, dark));
    }

    &--arrow-bottom-right {
        @include arrow(bottom, right, map-fetch($color, ui, dark));
    }

    &--arrow-bottom-center {
        @include arrow(bottom, center, map-fetch($color, ui, dark));
    }

    &--arrow-bottom-left {
        @include arrow(bottom, left, map-fetch($color, ui, dark));
    }

    &--arrow-left-bottom {
        @include arrow(left, bottom, map-fetch($color, ui, dark));
    }

    &--arrow-left-center {
        @include arrow(left, center, map-fetch($color, ui, dark));
    }

    &--arrow-left-top {
        @include arrow(left, top, map-fetch($color, ui, dark));
    }
}
