////
/// Disclose
/// @description Similar to an accordion but any number of items can be opened
/// closed independently.
/// @example[html]
///   <div class="#{$namespace}disclose is-active">
///     <a href="#" class="#{$namespace}disclose__link link--dark">
///       <div class="#{$namespace}disclose__arrow is-active">
///         <span class="#{$namespace}disclose__symbol"></span>
///         Title of Disclosure
///       </div>
///     </a>
///     <div class="#{$namespace}disclose__content">
///       <h3>Some Title</h3>
///       <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi id voluptas vitae eius expedita alias iste deserunt rndis earum voluptatibus quae, numquam dolorum perspiciatis accusantium corporis, beatae maxime quasi. Tempora.</p>
///     </div>
///   </div>
////

@import "../../oui/oui-utils";
@import "../../overrides/Margin/index.scss";

.#{$namespace}disclose,
.oui-disclose,
%oui-disclose {
    @extend %kill-last-child-margin;

    &__link {
        display: block;
        position: relative;
        margin-bottom: 5px;
    }

    &__symbol {
        width: spacer(1);
        display: inline-block;
        pointer-events: none; // Make clicking the arrow icon still open the disclose object.
        position: relative;
        top: 1px;
        margin-right: spacer(0.5);
    }

    .oui-disclose__arrow {
        // Get the highlighted cover for the hover state
        .axiom-icon {
            color: inherit;
        }
    }

    &__content {
        display: none;
    }

    &__item {
        margin-bottom: spacer(1);
    }

    &.is-active {
        .#{$namespace}disclose__content,
        .oui-disclose__content {
            display: block;
        }

        .oui-disclose__content > :last-child {
            padding-bottom: 20px;
        }
    }
}
