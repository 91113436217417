// These styles have been deprecated 2021-09-09. Use the Typography component instead.

@use "@optimizely/design-tokens/dist/scss/typography-map" as axiom-typography;

h1,
.alpha {
    @each $property, $value in axiom-typography.$header-1 {
        #{$property}: $value;
    }
}

h2,
.beta {
    @each $property, $value in axiom-typography.$header-2 {
        #{$property}: $value;
    }
}

h3,
.gamma {
    @each $property, $value in axiom-typography.$header-3 {
        #{$property}: $value;
    }
}

h4,
.delta {
    @each $property, $value in axiom-typography.$header-4 {
        #{$property}: $value;
    }
}

h5,
.epsilon {
    @each $property, $value in axiom-typography.$header-5 {
        #{$property}: $value;
    }
}

h6,
.zeta {
    @each $property, $value in axiom-typography.$caption {
        #{$property}: $value;
    }
}

.subhead {
    @each $property, $value in axiom-typography.$subhead {
        #{$property}: $value;
    }
}

// ## Heading Margin Defaults

h1 {
    margin-bottom: spacer(2);
}

h2 {
    margin-bottom: spacer(2);
}

h3 {
    margin-bottom: spacer(1);
}

// ## Text Size Overrides
// scss-lint:disable ImportantRule

.milli {
    font-size: map-fetch($font, size, milli) !important;
}

.micro {
    font-size: map-fetch($font, size, micro) !important;
}

.giga {
    font-size: map-fetch($font, size, giga) !important;
}

.mega {
    font-size: map-fetch($font, size, mega) !important;
}

.kilo {
    font-size: map-fetch($font, size, kilo) !important;
}
