@import "../../oui/oui-utils";

.oui-tile {
    max-height: 72px;
    background-color: white;
    border-radius: map-fetch($border, radius, base);
    border: 1px solid map-fetch($border, color, base);

    &:hover {
        border: solid 1px map-fetch($color, ui, brand);
    }

    &--selected {
        border: solid 1px map-fetch($color, ui, brand);
        background-color: $brand-blue-light;
    }

    &:focus-within {
        border: solid 1px map-fetch($color, ui, brand);
    }

    .oui-button--unstyled:focus {
        border: none !important;
        box-shadow: none !important;
        padding: spacer(1);
    }
    .oui-button--unstyled {
        padding-top: spacer(1) !important;
        padding-bottom: spacer(1) !important;
    }
}

.oui-tile__name {
    min-height: map-fetch($font, size, base);
}

.oui-tile__order-number {
    min-width: 16px;
}

.oui-tile__drag-handle {
    display: flex;
    cursor: move;
}
