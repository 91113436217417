@use "../../oui/partials/sass/mixins" as *;
@import "../../oui/oui-utils";

$sheet-width: map-fetch($dialog, width, wide);
$sheet-horizontal-padding: spacer(6);

// TODO: remove this when we have a better solution for CSS bundling.
.oui-close-button {
    @include flex-center;
    position: absolute;
    border-radius: 50%;
    cursor: pointer;
    background: none;
    border: none;
    top: spacer(0.5);
    right: spacer(0.5);
    box-shadow: none;
    padding: spacer(1);

    .oui-icon {
        fill: var(--dark-blue-75);
    }

    &:hover {
        background: var(--light-blue-6);
        color: var(--light-blue-75);
    }
}

.oui-sheet {
    position: fixed;
    bottom: 0;
    height: 95%;
    box-shadow: 0 0 map-fetch($shadow, big);
    width: $sheet-width;
    background: map-fetch($color, background, white);
    padding-top: spacer(6);
}

.oui-sheet__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: map-fetch($z-index, dialog);
}

.oui-sheet__overlay {
    background: map-fetch($color, background, white);
    opacity: map-fetch($opacity, heavy);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
}

.oui-sheet__header {
    padding: spacer(0) $sheet-horizontal-padding spacer(2);
}

.oui-sheet__footer {
    position: fixed;
    bottom: 0;
    width: $sheet-width;
    background: map-fetch($dialog, footer, color);
    border-top: 1px solid map-fetch($color, ui, light);
    padding: spacer(1) $sheet-horizontal-padding;
}

.oui-sheet__body {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: spacer(1) $sheet-horizontal-padding spacer(6) $sheet-horizontal-padding;
}

.oui-sheet__link {
    margin-left: spacer(0.5);
}

.oui-sheet .oui-close-button {
    top: spacer(3);
    right: spacer(3);
}

.oui-sheet__warning {
    padding: spacer(2) $sheet-horizontal-padding;
}
