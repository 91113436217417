.user-group-products {
    width: 100%;
    position: relative;

    &__add-product {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 12px;
    }

    &__dropdown-selection {
        padding-bottom: 12px;
    }
}

.roles-selections {
    padding-top: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
