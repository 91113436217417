@use "@optimizely/design-tokens/dist/scss/colors" as axiom-colors;

.axiom-editable-dropdown {
    &__input {
        background-color: axiom-colors.$editable-dropdown-static-background-color;
        border: 1px solid axiom-colors.$editable-dropdown-static-border-color;
        box-shadow: unset;

        &::placeholder {
            color: axiom-colors.$editable-dropdown-static-placeholder-color;
        }

        &:focus {
            background-color: axiom-colors.$editable-dropdown-static-focus-background-color;
            border-color: axiom-colors.$editable-dropdown-static-focus-border-color;
            box-shadow: unset;

            &::placeholder {
                color: axiom-colors.$editable-dropdown-static-focus-placeholder-color;
            }

            & + .oui-text-input__right-container {
                .axiom-editable-dropdown__input-arrow {
                    color: axiom-colors.$editable-dropdown-static-focus-icon-color;
                }
            }
        }

        &:hover {
            background-color: axiom-colors.$editable-dropdown-static-hover-background-color;
            border-color: axiom-colors.$editable-dropdown-static-hover-border-color;

            &::placeholder {
                color: axiom-colors.$editable-dropdown-static-hover-placeholder-color;
            }

            & + .oui-text-input__right-container {
                .axiom-editable-dropdown__input-arrow {
                    color: axiom-colors.$editable-dropdown-static-hover-icon-color;
                }
            }
        }

        & + .oui-text-input__right-container {
            .axiom-editable-dropdown__input-arrow {
                color: axiom-colors.$editable-dropdown-static-icon-color;
                cursor: pointer;

                &:hover {
                    color: axiom-colors.$editable-dropdown-static-hover-icon-color;
                }
            }
        }
    }

    &--error {
        .axiom-editable-dropdown__input:not([disabled]) {
            background-color: axiom-colors.$editable-dropdown-error-background-color;
            border-color: axiom-colors.$editable-dropdown-error-border-color;

            &::placeholder {
                color: axiom-colors.$editable-dropdown-error-placeholder-color;
            }

            &:focus {
                background-color: axiom-colors.$editable-dropdown-error-focus-background-color;
                border-color: axiom-colors.$editable-dropdown-error-focus-border-color;
                box-shadow: unset;

                &::placeholder {
                    color: axiom-colors.$editable-dropdown-error-focus-placeholder-color;
                }

                & + .oui-text-input__right-container {
                    .axiom-editable-dropdown__input-arrow {
                        color: axiom-colors.$editable-dropdown-error-focus-icon-color;
                    }
                }
            }

            &:hover {
                background-color: axiom-colors.$editable-dropdown-error-hover-background-color;
                border-color: axiom-colors.$editable-dropdown-error-hover-border-color;

                &::placeholder {
                    color: axiom-colors.$editable-dropdown-error-hover-placeholder-color;
                }

                & + .oui-text-input__right-container {
                    .axiom-editable-dropdown__input-arrow {
                        color: axiom-colors.$editable-dropdown-error-hover-icon-color;
                    }
                }
            }

            & + .oui-text-input__right-container {
                .axiom-editable-dropdown__input-arrow {
                    color: axiom-colors.$editable-dropdown-error-icon-color;

                    &:hover {
                        color: axiom-colors.$editable-dropdown-error-hover-icon-color;
                    }
                }
            }
        }
    }

    &--disabled {
        .axiom-editable-dropdown__input {
            background-color: axiom-colors.$editable-dropdown-disabled-background-color;
            border-color: axiom-colors.$editable-dropdown-disabled-border-color;

            &::placeholder {
                color: axiom-colors.$editable-dropdown-disabled-placeholder-color;
            }

            & + .oui-text-input__right-container {
                .axiom-editable-dropdown__input-arrow {
                    color: axiom-colors.$editable-dropdown-disabled-icon-color;
                    cursor: default;
                }
            }
        }

        .oui-label {
            color: axiom-colors.$editable-dropdown-disabled-label-color;
        }
    }

    .oui-form-note {
        color: axiom-colors.$editable-dropdown-error-note-color;
    }
}
