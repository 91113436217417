// Copyright 2017 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.

@use 'sass:list';
@use 'sass:map';
@use 'sass:math';
@use './variables';

// Returns the lower grid boundary or null if the smallest grid is selected
@function breakpoint-min($size) {
    @if not map.has-key(variables.$columns, $size) {
        @error "Invalid style specified! Choose one of #{map.keys(variables.$columns)}";
    }
    $min: map.get(variables.$breakpoints, $size);

    @return if($min > 0, $min, null);
}

// Returns the upper grid boundary or null if the largest grid is selected
@function breakpoint-max($size) {
    @if not map.has-key(variables.$columns, $size) {
        @error "Invalid style specified! Choose one of #{map.keys(variables.$columns)}";
    }
    $names: map.keys(variables.$columns);
    $n: list.index($names, $size);
    $prev: if($n > 1, list.nth($names, $n - 1), null);

    @return if($prev, (breakpoint-min($prev) - 1px), null);
}

@mixin media-query_($size) {
    @if not map.has-key(variables.$columns, $size) {
        @error "Invalid style specified! Choose one of #{map.keys(variables.$columns)}";
    }

    $min: breakpoint-min($size);
    $max: breakpoint-max($size);

    @if $min == null and $max != null {
        // Small
        @media (max-width: $max) {
            @content;
        }
    } @else if $min != null and $max != null {
        // Medium
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    } @else if $min != null and $max == null {
        // Large
        @media (min-width: $min) {
            @content;
        }
    } @else {
        // Fallback - no breakpoints defined
        @content;
    }
}

@mixin cell-span_($size, $span, $gutter) {
    @if not map.has-key(variables.$columns, $size) {
        @error "Invalid style specified! Choose one of #{map.keys(variables.$columns)}";
    }

    $percent: math.percentage($span / map.get(variables.$columns, $size));

    @if $percent > 100% {
        $percent: 100%;
    }

    @if $gutter > 0 {
        width: calc(#{$percent} - #{$gutter});
        width: calc(#{$percent} - var(--axiom-layout-grid-gutter-#{$size}, #{$gutter}));
    } @else {
        width: #{$percent};
    }

    @supports (display: grid) {
        width: auto;
        grid-column-end: span math.min($span, map.get(variables.$columns, $size));
    }
}

@mixin layout-grid($size, $margin, $max-width: null) {
    @if not map.has-key(variables.$columns, $size) {
        @error "Invalid style specified! Choose one of #{map.keys(variables.$columns)}";
    }

    box-sizing: border-box;
    margin: 0 auto;
    padding: $margin;
    padding: var(--axiom-layout-grid-margin-#{$size}, #{$margin});

    @if $max-width {
        max-width: $max-width;
    }
}

@mixin inner($size, $margin, $gutter) {
    @if not map.has-key(variables.$columns, $size) {
        @error "Invalid style specified! Choose one of #{map.keys(variables.$columns)}";
    }

    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -$gutter / 2;
    margin: calc(var(--axiom-layout-grid-gutter-#{$size}, #{$gutter}) / 2 * -1);

    @supports (display: grid) {
        display: grid;
        margin: 0;
        grid-gap: $gutter;
        grid-gap: var(--axiom-layout-grid-gutter-#{$size}, $gutter);
        grid-template-columns: repeat(map.get(variables.$columns, $size), minmax(0, 1fr));
    }
}

@mixin cell($size, $default-span, $gutter) {
    @if not map.has-key(variables.$columns, $size) {
        @error "Invalid style specified! Choose one of #{map.keys(variables.$columns)}";
    }

    @include cell-span_($size, $default-span, $gutter);

    box-sizing: border-box;
    margin: $gutter / 2;
    margin: calc(var(--axiom-layout-grid-gutter-#{$size}, #{$gutter}) / 2);

    @supports (display: grid) {
        margin: 0;
    }
}
